import { ConfigurationModel } from "@/views/private/timeline/models/configuration.model";
import GeneralUserModel from "./generaluser.model";
import PhoneNumberModel from "./phonenumber.model";
import { QRCodeSetting } from "./qrcode-setting.model";
import { UserProfileConfiguration } from "./settings.model";
import SocialLinks from "./sociallinks.model";
import { SocketStock } from "./stock/socket_stock.model";
import { TraderverseThemes } from "@/services/theme.service";


class Trade {
    typeOfTrading: string | undefined
    levelOfExperience: string | undefined
}
export default class User extends GeneralUserModel {
    uid: string | undefined;
    createdOn: string | undefined;
    modifiedOn: string | undefined;
    password: string | undefined;
    phoneNumber: PhoneNumberModel = new PhoneNumberModel();
    dob: string | undefined;
    bio: string | undefined;
    shortBIO: string | undefined;
    interestedIn: string | undefined;
    address: string | undefined;
    isFollowing: boolean | undefined;
    isFriend: boolean | undefined;
    isRequestSent: boolean | undefined;
    isBlocked: boolean | undefined;
    isGuestUser: boolean | undefined;
    isPublic: boolean = false;
    socialLinks: SocialLinks[] = [];
    isAuthenticatedWithTwitter: boolean = false;
    isAuthenticatedWithLinkedin: boolean = false;
    isAuthenticatedWithReddit: boolean = false;
    language: string | undefined;
    isOnboardingFinished: boolean = false
    trade: any = new Trade()
    favouriteStocks: SocketStock[] = [];
    trading: any[] = [];
    stats: any;
    twitterScreenName: string | undefined
    isEmailVerified: boolean = false;
    isPhoneVerified: boolean = false;
    images: any = {};
    isProfileCompleted: boolean = false;
    qrcode: QRCodeSetting = new QRCodeSetting();
    profileConfiguration: UserProfileConfiguration = new UserProfileConfiguration();
    websiteColor: string = "#191527";
    websiteWallpaper: string | undefined;
    configuration: ConfigurationModel = new ConfigurationModel();
    selectedTheme: TraderverseThemes | undefined;
    notificationReadTime: any | undefined = {};
}
