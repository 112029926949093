import utilService from "@/services/util.service";
import { Socket, io } from "socket.io-client";
import { ref, watchEffect } from "vue";

let socket: Socket;
const userDataInfo = ref<any>({});
const userIds = ref<any>([]);

export const useUserSocket = () => {
  const connectToUserSocket = () => {
    if (!socket || !socket.connected) {
      socket = io(utilService.getLiveHostURL());
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (error) => {});
    }
  };

  var count = 1;
  const getUsersByID = () => {
    if (socket && socket.connected && userIds.value.length) {
      count++;
      socket.emit("users", JSON.stringify(userIds.value), (response: any) => {
        if (response) {
          if (response.length > 0) {
            response.forEach((data: any) => {
              userDataInfo.value[data._id] = data;
            });
          }
        }
      });
    }
  };

  const clearUserIds = () => {
    userIds.value = [];
    userDataInfo.value = {};
  };

  const disconnectUsersFromSocket = () => {
    if (socket) {
      socket.removeAllListeners();
      socket.close();
    }
  };

  watchEffect(() => {
    if (userIds.value.length) {
      getUsersByID();
    }
  });

  return {
    connectToUserSocket,
    getUsersByID,
    clearUserIds,
    disconnectUsersFromSocket,
    userDataInfo,
    userIds,
  };
};
