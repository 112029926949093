<template>
    <select
      v-model="selected"
      @change="updateSelectedOption($event.target.value)"
    >
      <slot></slot>
    </select>
  </template>
  
  <script>
  import { onMounted, ref } from "vue";
  import "materialize-css";
  
  export default {
    props: {
      modelValue: {
        default: null,
      },
    },
    emits: ["update:modelValue"],
    setup(props, context) {
      const selected = ref(props.modelValue);
  
      onMounted(() => {
        const elems = document.querySelectorAll("select");
        if (elems && elems.length) {
          M.FormSelect.init(elems);
        }
      });
  
      const updateSelectedOption = (value) => {
        props.modelValue = value;
        context.emit("update:modelValue", value);
      };
  
      return {
        updateSelectedOption,
        selected,
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "./MaterialSelect.scss";
  </style>
  