<template>
  <div class="modal confirmationDialogWrapper">
    <div class="confirmationDialog">
      <h6>{{ message }}</h6>
      <div class="confirmationDialog_buttons">
        <button class="btn btn--outline btn--sm" @click="onCancel()">
          {{ cancelText }}
        </button>
        <button class="btn btn--primary btn--sm" @click="onConfirm()">
          {{ confirmationText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: {
      type: String,
      default: "Are you sure?",
    },
    confirmationText: {
      type: String,
      default: "Yes",
    },
    cancelText: {
      type: String,
      default: "No",
    },
  },
  setup(props, context) {
    const onConfirm = () => context.emit("confirm");
    const onCancel = () => context.emit("cancel");

    return {
      onConfirm,
      onCancel,
    };
  },
});
</script>


<style lang="scss" scoped>
.confirmationDialogWrapper {
  position: relative;
  z-index: 999999999999 !important;
}
body 
    .confirmationDialog{
        min-width: 340px;min-height: 180px;background: var(--base-dark);z-index: 999999999999;padding: 20px;overflow: hidden;display: flex;flex-flow: column;align-items: center;justify-content: center;border-radius: 8px;    margin-left: calc(var(--closedSidebarWidth) + 45px);
        h6{
            margin: 0 0 20px;font-size: var(--font-lg);font-weight: 500;
        }
        &_buttons{
            width: 80%;display: flex;flex-flow: column;gap: 10px;
            button{
                margin: 0;
            }
        }
    }
</style>