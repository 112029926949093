<template>
  <template v-if="fromProfileSharePopup">
    <div class="stock_stats" v-if="!hiddenInfo">
      <div v-if="hideStocksName" class="statsRow">
        <div class="stat" v-for="stat in totalStats" :key="stat">
          <span
            class="color"
            :style="{
              background: stat.color,
            }"
          ></span>
          <p>
            {{ $filters.convertToTitleCase(stat.title)
            }}<template v-if="showCount">({{ stat.count }})</template>
          </p>
        </div>
      </div>
    </div>
    <div class="secondInfoStockDv" ref="scrollElem">
      <template v-for="stat in totalStats" :key="stat">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="stat.title.toUpperCase() + '(' + stat.count + ')'"
          placement="top-start"
        >
          <div
            class="stat"
            :class="{
              borderChanging: totalStats && totalStats.length === 1,
            }"
            :style="{
              maxWidth: stat.maxWidth == 0 ? '2%' : stat.maxWidth + '%',
            }"
          >
            <h2
              class="ColorBlack"
              v-if="stat.percent !== 'NaN' && Math.floor(stat.percent) !== 0"
            >
              {{ Math.floor(stat.percent) }}%
            </h2>
            <div
              class="color"
              :style="{
                background: stat.color,
              }"
            ></div>
          </div>
        </el-tooltip>
      </template>
    </div>
  </template>
  <template v-else>
    <div
      class="scroll-container stock_stats"
      :class="{
        fromqrcodescreen: fromqrcodescreen,
        shareSection: sectionShare,
      }"
      v-if="!hiddenInfo"
    >
      <div class="custom-scrollbar" ref="scrollBar"></div>
      <div
        v-if="hideStocksName"
        class="scroll-content statsRow"
        :class="{
          fromSideMenu: fromSideMenu,
        }"
        ref="scrollElem"
      >
        <div class="stat" v-for="stat in totalStats" :key="stat">
          <span
            class="color"
            :style="{
              background: stat.color,
            }"
          ></span>
          <p>
            {{ $filters.convertToTitleCase(stat.title)
            }}<template v-if="showCount">({{ stat.count }})</template>
          </p>
        </div>
      </div>
    </div>
    <div
      class="secondInfoStockDv"
      ref="scrollElem"
      :class="{
        fromqrcodescreen: fromqrcodescreen,
        shareSection: sectionShare,
        fromSideMenuNew: fromSideMenu,
      }"
    >
      <template v-for="stat in totalStats" :key="stat">
        <el-tooltip
          style="cursor: default"
          class="box-item"
          effect="dark"
          :content="stat.title.toUpperCase() + '(' + stat.count + ')'"
          placement="top-start"
        >
          <div
            class="stat"
            :class="{
              oneStats: totalStats && totalStats.length === 1,
            }"
            v-if="stat.maxWidth > 0"
            :style="{
              maxWidth: stat.maxWidth == 0 ? '2%' : stat.maxWidth + '%',
            }"
          >
            <h2
              class="ColorBlack"
              v-if="stat.percent !== 'NaN' && Math.floor(stat.percent) !== 0"
            >
              {{ Math.floor(stat.percent) }}%
            </h2>
            <div
              class="color"
              :style="{
                background: stat.color,
              }"
            ></div>
          </div>
        </el-tooltip>
      </template>
    </div>
  </template>
</template>

<script lang="ts">
import { SocketStock } from "@/helpers/models/stock/socket_stock.model";
import utilService from "@/services/util.service";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";

export default defineComponent({
  props: {
    fromProfileSharePopup: Boolean,
    hiddenInfo: Boolean,
    sectionShare: Boolean,
    fromSideMenu: Boolean,
    fromqrcodescreen: Boolean,
    defaultPx: {
      type: Number,
      default: 10,
    },
    stocks: {
      type: Array as PropType<SocketStock[]>,
      default: [],
    },
    showCount: {
      type: Boolean,
      default: true,
    },
    hideStocksName: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const scrollBar = ref<any>();
    const scrollElem = ref<any>();

    onMounted(() => {
      utilService.draggableScroll(scrollElem.value, scrollBar.value);
      setStock();
    });

    watch(props, () => {
      setStock();
    });

    const totalStats = ref<any>();

    const setStock = () => {
      setTimeout(() => {
        totalStats.value = utilService.getStocksBar(
          props.stocks,
          scrollElem.value ? scrollElem.value.clientWidth : "100%",
          6
        );
        if (totalStats.value && totalStats.value.length) {
          let info: any = [];
          for (let item of totalStats.value) {
            if (item.maxWidth === 0) {
            } else {
              info.push(item);
            }
          }
          totalStats.value = info;
        }
      }, 100);
    };

    return {
      scrollBar,
      scrollElem,
      totalStats,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "./investment-bar";
.borderChanging {
  .color {
    border-radius: 5px 5px 5px 5px !important;
  }
}
.ColorBlack {
  color: black !important;
}
.fromSideMenu {
  padding: 0px !important;
}
.fromSideMenuNew {
  margin: var(--section-head-vertical-spacing) 0px 0px;
  margin-bottom: 0px !important;
}
</style>
