export default class GeneralUserModel {
  country: string | undefined;
  email: string | undefined;
  gender: string | undefined;
  fullName: string | undefined;
  userName: string | undefined;
  _id: string | undefined;
  followersCount: number | undefined;
  followingCount: number | undefined;
  friendCount: number | undefined;
  mutualCount: number | undefined;
  profilePicture: number = 0;
  coverPhoto: number = 0;

  constructor(
    id: string = "",
    fullName: string = "",
    userName: string = "",
    gender: string = "",
    profilePicture: number = 0
  ) {
    this._id = id;
    this.fullName = fullName;
    this.gender = gender;
    this.profilePicture = profilePicture;
    this.userName = userName;
  }
}
