import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "stat_item-text" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 2,
  class: "stat_item-icon",
  ref: "statIconDv"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconComponent = _resolveComponent("IconComponent")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (!_ctx.isEditableView || _ctx.predefined || _ctx.value != 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["stat_item", {
      shadeTwo: _ctx.predefined || _ctx.colorFade,
      compact: _ctx.predefined,
      nonValue: _ctx.value == 0,
      selectedOutline: _ctx.stat.isSelected && _ctx.hideTick,
      isLink: _ctx.stat.key == 'followersCount' || _ctx.stat.key == 'followingCount',
      disabled: _ctx.disabled,
      'c-pointer': !_ctx.showRemove,
    }]),
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handleClick({..._ctx.stat, calcVal: _ctx.$filters.getStatsValue(_ctx.stat.key, _ctx.value ?? 0)})), ["stop"])),
        style: _normalizeStyle({ borderColor: _ctx.color })
      }, [
        (_ctx.showRemove)
          ? (_openBlock(), _createBlock(_component_IconComponent, {
              key: 0,
              icon: "minus.svg",
              class: "topRightIcon c-pointer",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onRemove()), ["stop"])),
              onTouchstart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRemove()))
            }))
          : _createCommentVNode("", true),
        (_ctx.showSelected && _ctx.stat.isSelected && !_ctx.hideTick)
          ? (_openBlock(), _createBlock(_component_IconComponent, {
              key: 1,
              class: "topRightIcon",
              icon: "icon-tick-info-dv-two.svg"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.isOverflowing)
            ? (_openBlock(), _createBlock(_component_el_tooltip, {
                key: 0,
                class: "box-item",
                effect: "dark",
                content: _ctx.stat.label,
                placement: "top-start"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h6", { ref: "label" }, _toDisplayString(_ctx.stat.label), 513)
                ]),
                _: 1
              }, 8, ["content"]))
            : (_openBlock(), _createElementBlock("h6", {
                key: 1,
                ref: "label"
              }, _toDisplayString(_ctx.stat.label), 513)),
          _createElementVNode("p", {
            innerHTML: _ctx.$filters.getStatsValue(_ctx.stat.key, _ctx.value ?? 0)
          }, null, 8, _hoisted_2)
        ]),
        (_ctx.icon && _ctx.showIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_IconComponent, {
                icon: _ctx.icon + '.svg',
                path: "/stats-icon"
              }, null, 8, ["icon"])
            ], 512))
          : _createCommentVNode("", true)
      ], 6))
    : _createCommentVNode("", true)
}