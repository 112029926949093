import { TraderverseThemes } from "@/services/theme.service";
import { ConfigurationModel } from "./configuration.model";
import GeneralUserModel from "./generaluser.model";
import PhoneNumberModel from "./phonenumber.model";
import SocialLinks from "./sociallinks.model";


class Trade
{
    typeOfTrading: string | undefined
    levelOfExperience: string | undefined
}
export default class User extends GeneralUserModel
{
    uid: string | undefined;
    createdOn: string | undefined;
    modifiedOn: string | undefined;
    password: string | undefined;
    phoneNumber: PhoneNumberModel = new PhoneNumberModel();
    dob: string | undefined;
    bio: string | undefined;
    shortBIO: string | undefined;
    interestedIn: string | undefined;
    address: string | undefined;
    isFollowing: boolean | undefined;
    isFriend: boolean | undefined;
    isRequestSent: boolean | undefined;
    isBlocked: boolean | undefined;
    isPublic: boolean = false;
    configuration: ConfigurationModel = new ConfigurationModel();
    socialLinks: any = new SocialLinks();
    isAuthenticatedWithTwitter: boolean = false;
    language: string | undefined;
    isOnboardingFinished: boolean = false
    trade: any = new Trade()
    twitterScreenName: string | undefined
    selectedTheme: TraderverseThemes | undefined
}