import { isValueValid } from "../helpers/constants";
import ChartHeader from "./chartHeader.model";
import { StockMarkets } from "./stocks.model";
import WatchList from "./watchList.modal";
import utilService from "@/services/util.service";
import filters from "@/helpers/filters";
import { SocketStock } from "./socket_stock.model";

class GeneralStockModel {
  _id: string = "";
  companyName: string = "";
  company_name: string = "";
  name: string = "";
  ticker: string = "";
  symbol: string = "";
  price: number = 0;
  change: number = 0;
  changePercent: number | any = 0;
  changesPercentage: number | any = 0;
  industry: string = "";
  historicalData: any;
  country: string = "USA";
  logoUrl: string = "";
  exchange: string = "";
  type: string = StockMarkets.stocks;
  marketCap: number = 0;
  // old property to remain same
  stockId: string = "";
  last: number = 0;
  // fronted
  isActive: boolean = false;
  // for watchlist
  description: string = "";
  predictedPrice: number | undefined;
  predictionDate: string = "";
  snapshotData: SnapshotData | undefined = undefined;
  technicalIndicators: string[] = [];
  predicition: object = {};
  isOpen: boolean = false;
  tradeDate: any;
  chartData:any;
  public static copy(
    destination: GeneralStockModel | undefined,
    source: any
  ): GeneralStockModel {
    if (!destination) {
      destination = new GeneralStockModel();
    }
    try {
      destination._id = source._id ?? source.symbol;
      destination.companyName =
        source.companyName && isValueValid(source.companyName)
          ? source.companyName
          : "";
      destination.symbol =
        source.symbol && isValueValid(source.symbol) ? source.symbol : "";
      destination.stockId =
        source.symbol && isValueValid(source.stockId) ? source.symbol : "";
      destination.price = filters.systemNumberConvention(source.price);
      destination.change = filters.systemNumberConvention(source.change);
      destination.marketCap = filters.systemNumberConvention(source.marketCap);
      destination.changePercent = filters.systemNumberConvention(source.changePercent);
      destination.industry =
        source.industry && isValueValid(source.industry)
          ? source.industry
          : "-";
      destination.historicalData =
        source.historicalData && isValueValid(source.historicalData)
          ? source.historicalData
          : "";
      destination.country =
        source.country && isValueValid(source.country) ? source.country : "";
      destination.exchange =
        source.exchange && isValueValid(source.exchange) ? source.exchange : "";
      destination.logoUrl = utilService.getStockCDNImage(source.logoUrl, destination._id, destination.exchange ?? StockMarkets.stocks);
      destination.type =
        source.type && isValueValid(source.type) ? source.type : "";
      if (destination.isActive == null || destination.isActive == undefined) {
        destination.isActive = false;
      }
      if ((!destination.exchange || destination.exchange == '') && destination._id.length) {
        destination.exchange = destination._id.split('_')[1];
      }
      return destination;
    } catch (e) {
      return destination;
    }
  }

  public static getWatchlist(destination: GeneralStockModel): WatchList {
    var watchlist = new WatchList();
    let price = +filters.systemNumberConvention(destination.price);
    if(isNaN(price) || (price == 0 && destination.price)) {
      price = destination.price
    }
    watchlist.country = destination.country;
    watchlist.description = destination.description;
    watchlist.name = destination.companyName;
    watchlist.price = price || 0;
    watchlist.symbol = destination.symbol;
    watchlist.market = destination.type;
    watchlist.change = +filters.systemNumberConvention(destination.change);
    watchlist.exchange = destination.exchange;
    watchlist.historicalData = destination.historicalData
      ? JSON.stringify(destination.historicalData)
      : "";
    watchlist.predictedPrice = filters.systemNumberConvention(
      destination.predictedPrice
    );
    watchlist.predictionDate = destination.predictionDate
      ? new Date(destination.predictionDate).toISOString()
      : "";
    watchlist.changePercent = +filters.systemNumberConvention(
      destination.changePercent,
      false, true
    );
    watchlist.stock_id = destination._id ?? destination.symbol;
    watchlist._id = destination._id ?? destination.symbol;
    watchlist.image = utilService.getStockCDNImage(destination.logoUrl, destination._id ?? destination.symbol, destination.exchange);
    watchlist.predicition = destination.predicition,
    watchlist.isOpen = destination.isOpen
    return watchlist;
  }

  public static copyFromWatchlist(source: WatchList): GeneralStockModel {
    var stock = new GeneralStockModel();
    stock._id = source.stock_id ?? source.symbol!;
    stock.country = source.country!;
    stock.description = source.description!;
    stock.companyName = source.name!;
    stock.price = filters.systemNumberConvention(source.price);
    stock.symbol = source.symbol!;
    stock.type = source.market!;
    stock.change = filters.systemNumberConvention(source.change);
    stock.exchange = source.exchange!;
    stock.historicalData = source.historicalData!
      ? JSON.stringify(source.historicalData)
      : "";
    stock.predictedPrice = filters.systemNumberConvention(source.predictedPrice);
    stock.predictionDate = source.predictionDate;
    stock.changePercent = filters.systemNumberConvention(source.changePercent, false, true);
    stock.logoUrl = utilService.getStockCDNImage(source.image!, stock.symbol, stock.exchange);
    return stock;
  }

  public static getChartHeader(destination: GeneralStockModel): ChartHeader {
    var header = new ChartHeader();
    if (destination) {
      if (destination.change) {
        header.change = filters.systemNumberConvention(destination.change)
      }
      if (destination.changePercent) {
        header.changePercent = filters.systemNumberConvention(destination.changePercent)
      } else if(destination.changesPercentage) {
        header.changePercent = destination.changesPercentage
      }
      header.companyName = destination.companyName || destination.name;
      if (destination.price) {
        header.last = destination.price;
      } else if (destination.last) {
        header.last = destination.last;
      }
      if (!destination._id || (destination._id && !destination._id.includes(destination.symbol) && destination._id.includes(destination.symbol))) {
        destination._id = destination.logoUrl.slice(destination.logoUrl.lastIndexOf('/') + 1, destination.logoUrl.lastIndexOf('.'));
      }
      header.stockId = destination.stockId;
      header._id = destination._id;
      header.symbol = destination.symbol;
      header.type = destination.type;
      header.logoUrl = utilService.getStockCDNImage(destination.logoUrl, destination._id ?? destination.symbol, header.type);
      
    
    }
    return header;
  }

  public static copyFromSnapshot(
    destination: GeneralStockModel | undefined,
    source: any
  ) {
    if (!destination) {
      destination = new GeneralStockModel();
    }
    destination.companyName = source.ticker
      ? source.ticker
      : source.companyName
        ? source.companyName
        : "";
    destination.symbol = source.symbol ?? "";
    destination.stockId = source.symbol ?? "";
    destination.price = filters.systemNumberConvention(source.last);
    destination.change = filters.systemNumberConvention(source.change);
    destination.marketCap = filters.systemNumberConvention(source.marketCap);
    destination.changePercent = filters.systemNumberConvention(
      source.changePercent
    );
    destination.industry = source.industry ?? "";
    destination.historicalData = source.historicalData ?? "";
    destination.country = source.country ?? "USA";
    destination.exchange = source.exchange ?? StockMarkets.stocks;
    destination.type = source.type ?? StockMarkets.stocks;
    destination.technicalIndicators = source.technicalIndicators;
    destination._id = source._id ?? source.symbol;
    destination.logoUrl = utilService.getStockCDNImage(source.logoUrl, destination._id, destination.type);
    if (destination.isActive == null || destination.isActive == undefined) {
      destination.isActive = false;
    }
    return destination;
  }


  public static createDataModel(
    _id: string,
    symbol: string,
    companyName: string,
    logoUrl: string,
    type: string,
    price: any,
    change: any,
    changePercent: any,
  ) {
    var destination = new GeneralStockModel();
    destination._id = _id ?? symbol;
    destination.symbol = symbol;
    destination.stockId = symbol;
    destination.price = filters.systemNumberConvention(price);
    destination.change = filters.systemNumberConvention(change);
    destination.changePercent = filters.systemNumberConvention(changePercent);
    destination.companyName = companyName;
    destination.type = type;
    destination.logoUrl = utilService.getStockCDNImage(logoUrl, destination._id, destination.type);
    return destination;
  }

  public static copyFromSocketModel(
    destination: GeneralStockModel | undefined,
    source: SocketStock
  ) {
    if (!destination) {
      destination = new GeneralStockModel();
    }
    if (destination.companyName != source.name) {
      destination.companyName = source.name!;
    }
    if (destination.price != source.price) {
      destination.price = source.price!;
    }
    if (destination.symbol != source.symbol) {
      destination.symbol = source.symbol!;
    }
    if (destination.type != source.type) {
      destination.type = source.type ?? "stocks";
    }
    if (destination.change != source.change) {
      destination.change = source.change!;
    }
    if (destination.changePercent != source.changesPercentage) {
      destination.changePercent = source.changesPercentage!;
    }
    if (destination._id != source.stockId) {
      destination._id = source.stockId!;
    }
    if (destination.marketCap != source.marketCap) {
      destination.marketCap = source.marketCap!;
    }
    if (destination.exchange != source.exchange) {
      destination.exchange = source.exchange!;
    }
    if (destination.logoUrl != source.logoUrl) {
      destination.logoUrl = source.logoUrl!;
    }
    if (destination.historicalData && !destination.historicalData.length) {
      destination.historicalData = source.fiveDayTrend;
    }
    return destination;
  }

}

class SnapshotData {
  change: number = 0;
  chartData: any[] = [];
  companyName: string = "";
  last: number = 0;
  logoUrl: string = "";
  symbol: string = "";
  type: string = "";
  // existing properties
  changePercent: number = 0;
  price: number = 0;

  static getObjectFromGeneralStockModel(
    data: SnapshotData,
    obj: any
  ): SnapshotData {
    if (!obj._id) {
      obj._id = obj.stockId ?? obj.symbol;
    }
    if (data.change != obj.change) data.change = obj.change;
    if (data.chartData != obj.chartData) data.chartData = obj.chartData;
    if (data.companyName != obj.companyName) data.companyName = obj.companyName;
    if (data.last != obj.last) data.last = filters.systemNumberConvention(obj.last);
    if (data.symbol != obj.symbol) data.symbol = obj.symbol;
    if (data.type != obj.type) data.type = obj.type;
    if (data.logoUrl != obj.logoUrl) data.logoUrl = utilService.getStockCDNImage(obj.logoUrl, obj._id, data.type);
    // existing properties
    if (data.changePercent != obj.changePercent) data.changePercent = filters.systemNumberConvention(obj.changePercent);
    if (data.price != obj.last) data.price = filters.systemNumberConvention(obj.last);
    return data;
  }
}

export { GeneralStockModel, SnapshotData };
