import { AppEnvironments, Environment } from "@/environment";
import { SocketStock } from "@/helpers/models/stock/socket_stock.model";
import countriesJSON from "../assets/countries_list.json";
import {
  StockMarketColors,
  StockMarkets,
} from "@/helpers/models/stock/stocks.model";
import feelingsJSON from "../assets/json/feelings.json";
import { Ref, ref } from "vue";
import { TYPE, useToast } from "vue-toastification";
import storageService from "./storage.service";
import { openModal, pushModal } from "jenesius-vue-modal";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import { ConfigurationModel } from "@/views/private/timeline/models/configuration.model";
import httpService from "./http.service";
import User from "@/views/private/timeline/models/user.model";
import axios from "axios";
import { GeneralStockModel } from "@/views/private/timeline/models/generalstock.model";
import PostsModel from "@/views/private/timeline/models/posts.model";
import GeneralUserModel from "@/views/private/timeline/models/generaluser.model";
import ChartTypesJSON from "@/assets/json/chart_types.json";
import router from "@/router/router";
import { RouteEnum } from "@/router/routeNames";
import AlertDeleteComponent from "@/components/AlertDeleteComponent.vue";
import ConnectionModel from "@/helpers/models/connection.model";
import { userProfileUser } from "@/views/private/timeline/composables/profileuser.composable";
import {
  NewsFeedIds,
  NewsSearchDTO,
  NewsSourceLogos,
  NewsSourceTitles,
} from "../helpers/models/news-search.model";
import { useStockSocket } from "@/composables/stock_socket.composable";

class UtilService {
  chartColors: any[] = [];
  newsSources: NewsSearchDTO[] = [
    new NewsSearchDTO(
      NewsFeedIds.yahoo,
      NewsSourceTitles.yahoo,
      NewsSourceLogos.yahoo
    ),
    new NewsSearchDTO(
      NewsFeedIds.marketWatch,
      NewsSourceTitles.marketWatch,
      NewsSourceLogos.marketWatch
    ),
    new NewsSearchDTO(
      NewsFeedIds.cryptoDaily,
      NewsSourceTitles.cryptoDaily,
      NewsSourceLogos.cryptoDaily
    ),
    new NewsSearchDTO(
      NewsFeedIds.cointelegraph,
      NewsSourceTitles.cointelegraph,
      NewsSourceLogos.cointelegraph
    ),
  ];

  constructor() {
    for (let i = 0; i < 12; i++) {
      this.chartColors.push(this.generateRandomLightColor());
    }
  }

  generateRandomLightColor = () => {
    let color = "#";
    for (let i = 0; i < 3; i++)
      color += (
        "0" +
        Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)
      ).slice(-2);
    return color;
  };

  goToStockScreen = (stockId: string, market: string = "stocks") => {
    router.push({
      name: market,
      params: { stock: stockId },
    });
  };

  showToast(
    message: string,
    type: TYPE = TYPE.SUCCESS,
    duration: number = 2500
  ) {
    const toast = useToast();
    if (type === TYPE.SUCCESS) {
      toast.success(message, {
        timeout: duration,
      });
    } else if (type === TYPE.ERROR) {
      toast.error(message, {
        timeout: duration,
      });
    } else if (type === TYPE.WARNING) {
      toast.warning(message, {
        timeout: duration,
      });
    } else if (type === TYPE.INFO) {
      toast.info(message, {
        timeout: duration,
      });
    }
    return { toast };
  }

  coverImageError = () => this.getUserProfilePicture("", 0, "", true, true);

  get FirebaseConfig() {
    if (Environment.currentEnvironment == AppEnvironments.production) {
      return {
        apiKey: "AIzaSyCrkiZKoUgjzr4MngmlE76MqK7xGLv76AU",
        authDomain: "traderverse-75f26.firebaseapp.com",
        databaseURL: "https://traderverse-75f26-default-rtdb.firebaseio.com",
        projectId: "traderverse-75f26",
        storageBucket: "traderverse-75f26.appspot.com",
        messagingSenderId: "181344263879",
        appId: "1:181344263879:web:42efa1997fbf2e22e9a8e2",
        measurementId: "G-H3C5TY7Z06",
      };
    } else if (Environment.currentEnvironment == AppEnvironments.stage) {
      return {
        apiKey: "AIzaSyCrkiZKoUgjzr4MngmlE76MqK7xGLv76AU",
        authDomain: "traderverse-75f26.firebaseapp.com",
        databaseURL: "https://traderverse-75f26-default-rtdb.firebaseio.com",
        projectId: "traderverse-75f26",
        storageBucket: "traderverse-75f26.appspot.com",
        messagingSenderId: "181344263879",
        appId: "1:181344263879:web:42efa1997fbf2e22e9a8e2",
        measurementId: "G-H3C5TY7Z06",
      };
    } else if (Environment.currentEnvironment == AppEnvironments.dev) {
      return {
        apiKey: "AIzaSyDQGiP1NMRy0xZ3-c-D89mLIDTr0mtfT5c",
        authDomain: "traderverse-prd.firebaseapp.com",
        databaseURL: "https://traderverse-prd-default-rtdb.firebaseio.com",
        projectId: "traderverse-prd",
        storageBucket: "traderverse-prd.appspot.com",
        messagingSenderId: "449478086469",
        appId: "1:449478086469:web:4aac44ff8b73d7a726b82c",
        measurementId: "G-0PKPFY359M",
      };
    } else if (Environment.currentEnvironment == AppEnvironments.internal) {
      return {
        apiKey: "AIzaSyBqvX0JLJGyjGIa0r2ut58pvP2GP6wpc8Y",
        authDomain: "internaldev-361116.firebaseapp.com",
        databaseURL: "https://internaldev-361116-default-rtdb.firebaseio.com",
        projectId: "internaldev-361116",
        storageBucket: "internaldev-361116.appspot.com",
        messagingSenderId: "740511496249",
        appId: "1:740511496249:web:d4975e6d15315884c3f5e8",
        measurementId: "G-M6R0R5WRJ8",
      };
    }
    return {
      apiKey: "AIzaSyCrkiZKoUgjzr4MngmlE76MqK7xGLv76AU",
      authDomain: "traderverse-75f26.firebaseapp.com",
      databaseURL: "https://traderverse-75f26-default-rtdb.firebaseio.com",
      projectId: "traderverse-75f26",
      storageBucket: "traderverse-75f26.appspot.com",
      messagingSenderId: "181344263879",
      appId: "1:181344263879:web:42efa1997fbf2e22e9a8e2",
      measurementId: "G-H3C5TY7Z06",
    };
  }

  getImageUrl = (url: string, directory: string | null) => {
    try {
      var images = require.context("../assets/images", true);
      var imageUrl = directory ? `./${directory}/${url}` : `./${url}`;
      return images(imageUrl);
    } catch (e) {
      return "";
    }
  };

  getConnectionsByKeyword = async (connectionType: string, keyword: string) => {
    if (keyword.length) {
      const response: any = await httpService.searchConnectionByType(
        connectionType,
        keyword
      );
      if (
        response.data.isSuccess &&
        response.data.data &&
        response.data.data.length
      ) {
        return response.data.data.map((x: any) => {
          var user = new GeneralUserModel();
          user = Object.assign(user, x);
          return user;
        });
      }
    }
    return [];
  };

  getSubscriptionsByKeyword = async (keyword: string) => {
    if (keyword.length) {
      const response: any = await httpService.searchSubscriptions(keyword);
      if (response.isSuccess && response.data && response.data.length) {
        return response.data.data;
      }
    }
    return [];
  };

  // getStockCryptoByKeyword = async (keyword: string) => {
  //   if (keyword.length) {
  //     const response: any = await httpService.searchStockCrypto(keyword);
  //     if (
  //       response.data.isSuccess &&
  //       response.data.data &&
  //       response.data.data.length
  //     ) {
  //       return response.data.data.map((x: any) => {
  //         var user = new GeneralStockModel();
  //         user = Object.assign(user, x);
  //         return user;
  //       });
  //     }
  //   }
  //   return [];
  // };

  getCountriesJSON() {
    return countriesJSON;
  }

  getShortCodeOfCountry(countryName: string) {
    if (countryName) {
      let countries = this.getCountriesJSON();
      let country = countries.find((x) => x.name == countryName);
      if (country && country.code) {
        return country.code;
      }
    }
    return "";
  }

  getUserProfilePicture = (
    userId: string,
    count: number,
    userGender: string,
    isCoverPicture: boolean = false,
    showDummy: boolean = true
  ) => {
    if (count > 0) {
      const type = isCoverPicture ? "coverphoto" : "profilepicture";
      return `https://firebasestorage.googleapis.com/v0/b/${this.FirebaseConfig.storageBucket}/o/images%2F${type}_${userId}_v_${count}?alt=media`;
    } else if (showDummy) {
      if (isCoverPicture) {
        return this.getImageUrl("dummy-cover.png", null);
      } else {
        // if (userGender == "Male") {
        return this.getImageUrl("avatar_men.png", null);
        // } else {
        //   return this.getImageUrl("avatar_women.png", null);
        // }
      }
    } else {
      return "";
    }
  };

  getSectionImage = (userId: string, sectionName: string, type: string) => {
    return `https://firebasestorage.googleapis.com/v0/b/${this.FirebaseConfig.storageBucket}/o/share_${userId}_${type}_${sectionName}.png?alt=media`;
  };

  get Host(): string {
    if (Environment.currentEnvironment == AppEnvironments.stage) {
      return "https://social-stage.traderverse.io/";
    } else if (Environment.currentEnvironment == AppEnvironments.dev) {
      return "https://profiles-develop.traderverse.io/";
    } else if (Environment.currentEnvironment == AppEnvironments.production) {
      return "https://profiletest-uat.traderverse.io/";
    } else if (Environment.currentEnvironment == AppEnvironments.internal) {
      return "https://internal-profile.traderverse.io/";
    }
    return "https://profiles.traderverse.io/";
  }

  getHostURL() {
    return `${location.protocol}//${location.host}/`;
  }

  getLiveHostURL() {
    var url = this.getHostURL();
    if (url.includes("localhost")) {
      return this.Host;
    }
    return url;
  }

  getStockImage = (
    stockId: string,
    type: StockMarkets = StockMarkets.stocks
  ) => {
    if (stockId) {
      stockId = stockId.toLowerCase();
    }
    if (type == StockMarkets.stocks) {
      return this.getImageUrl(`dummy-stock.png`, "stocks");
    } else {
      return this.getImageUrl(`dummy-crypto.png`, "stocks");
    }
  };

  validatePassword = (password: string) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long";
    }
    if (password.includes(" ")) {
      return "Password can`t include empty spaces.";
    }
    var checkNumberExistence = /[0-9]/;
    if (!checkNumberExistence.test(password)) {
      return "Password must contain at least one number (0-9)";
    }
    var checkSmallAlphabetExistence = /[a-z]/;
    if (!checkSmallAlphabetExistence.test(password)) {
      return "Password must contain at least one lowercase letter (a-z)";
    }
    var checkLargeAlphabetExistence = /[A-Z]/;
    if (!checkLargeAlphabetExistence.test(password)) {
      return "Password must contain at least one uppercase letter (A-Z)";
    }
    var checkSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    if (!checkSpecialChar.test(password)) {
      return "Password must contain at least one special character.";
    }

    return "";
  };

  clampText = (
    textElem: any,
    field: Ref<boolean>,
    value: boolean,
    defaultLengthCheck: number = 96
  ) => {
    if (textElem != null) {
      if (
        (defaultLengthCheck < textElem.scrollHeight ||
          textElem.offsetWidth < textElem.scrollWidth) &&
        value
      ) {
        field.value = true;
        textElem.classList.add("truncate");
        textElem.classList.remove("nonTruncate");
      } else {
        field.value = false;
        textElem.classList.add("nonTruncate");
        textElem.classList.remove("truncate");
      }
    }
  };

  updateScrollbarWidth(content: any, scrollbar: any) {
    const visibleWidth = content.clientWidth;
    const totalWidth = content.scrollWidth;
    const ratio = visibleWidth / totalWidth;
    const scrollbarWidth = ratio * visibleWidth;

    scrollbar.style.width = `${scrollbarWidth}px`;
  }

  connectScrollbarAndContent(content: any, scrollbar: any) {
    content.addEventListener("scroll", function () {
      const scrollPercentage =
        (content.scrollLeft / (content.scrollWidth - content.clientWidth)) *
        100;
      const scrollbarPosition =
        (scrollPercentage * (content.clientWidth - scrollbar.clientWidth)) /
        100;
      scrollbar.style.left = `${scrollbarPosition}px`;
    });

    scrollbar.addEventListener("mousedown", function (event: any) {
      event.preventDefault();

      const startX = event.clientX;
      const initialScrollLeft = content.scrollLeft;

      function dragMove(e: any) {
        const deltaX = e.clientX - startX;
        const percentage =
          deltaX / (content.clientWidth - scrollbar.clientWidth);
        content.scrollLeft =
          initialScrollLeft +
          percentage * (content.scrollWidth - content.clientWidth);
      }

      function dragEnd() {
        document.removeEventListener("mousemove", dragMove);
        document.removeEventListener("mouseup", dragEnd);
      }

      document.addEventListener("mousemove", dragMove);
      document.addEventListener("mouseup", dragEnd);
    });
    this.updateScrollbarWidth(content, scrollbar);
  }

  showConfirmationDialog(msg: string = "Are you sure?") {
    return new Promise(async (resolve, reject) => {
      var modal = await pushModal(ConfirmationDialogComponent, {
        message: msg,
      });
      if (modal) {
        modal.on("cancel", () => {
          resolve(false);
          modal.close();
        });
        modal.on("confirm", () => {
          resolve(true);
          modal.close();
        });
      }
    });
  }

  isDragStart: boolean = false;

  draggableScroll(ele: any, scrollbar: any) {
    try {
      if (ele && ele.length) {
        ele = ele[0];
      }
      if (ele) {
        this.connectScrollbarAndContent(ele, scrollbar);
        ele.style.cursor = "grab";
        this.checkOverflow(ele);
        var self = this;
        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = function (e: any) {
          if (!self.isDragStart) {
            e.stopPropagation();
            ele.style.cursor = "grabbing";
            ele.style.userSelect = "none";

            pos = {
              left: ele.scrollLeft,
              top: ele.scrollTop,
              // Get the current mouse position
              x: e.clientX,
              y: e.clientY,
            };

            document.addEventListener("mousemove", mouseMoveHandler);
            document.addEventListener("mouseup", mouseUpHandler);
          }
        };

        const mouseMoveHandler = function (e: any) {
          if (!self.isDragStart) {
            e.stopPropagation();
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            // const dy = e.clientY - pos.y;

            // Scroll the element
            // ele.scrollTop = pos.top - dy;
            ele.scrollLeft = pos.left - dx;
          }
        };

        const mouseUpHandler = function () {
          if (!self.isDragStart) {
            ele.style.cursor = "grab";
            ele.style.removeProperty("user-select");

            document.removeEventListener("mousemove", mouseMoveHandler);
            document.removeEventListener("mouseup", mouseUpHandler);
          }
        };

        // Attach the handler
        ele.addEventListener("mousedown", mouseDownHandler);
      }
    } catch (e) {}
  }

  onDraggableDragEvent(type: "start" | "stop") {
    if (type == "start") {
      this.isDragStart = true;
      // setTimeout(() => {
      //   this.isDragStart = false;
      // }, 4000);
    } else {
      this.isDragStart = false;
    }
  }

  checkOverflow(ele: HTMLDivElement) {
    var hasOverflow = ele.scrollWidth > ele.clientWidth;
    if (hasOverflow) {
      ele.parentElement!.classList.add("canScroll");
    } else {
      ele.parentElement!.classList.remove("canScroll");
    }
  }

  showGuestUserFlow() {
    this.showToast("Please! Sign in to continue", TYPE.WARNING);
    storageService.RemoveAllProperties();
    setTimeout(() => {
      window.open("/signin", "_self");
    }, 100);
  }

  getStocksBar(
    stocks: SocketStock[],
    areaWidth: number,
    defaultPercentage: number = 4
  ) {
    var totalData: {
      title: string;
      count: number;
      color: string;
      maxWidth: any;
      percent: string;
    }[] = [
      {
        title: StockMarkets.stocks,
        count: 0,
        color: StockMarketColors.stocks,
        maxWidth: defaultPercentage,
        percent: "",
      },
      {
        title: StockMarkets.crypto,
        count: 0,
        color: StockMarketColors.crypto,
        maxWidth: defaultPercentage,
        percent: "",
      },
      {
        title: StockMarkets.etf,
        count: 0,
        color: StockMarketColors.etf,
        maxWidth: defaultPercentage,
        percent: "",
      },
      {
        title: StockMarkets.Futures,
        count: 0,
        color: StockMarketColors.Futures,
        maxWidth: defaultPercentage,
        percent: "",
      },
      {
        title: StockMarkets.nft,
        count: 0,
        color: StockMarketColors.nft,
        maxWidth: defaultPercentage,
        percent: "",
      },
      {
        title: StockMarkets.indices,
        count: 0,
        color: StockMarketColors.indices,
        maxWidth: defaultPercentage,
        percent: "",
      },
      {
        title: StockMarkets.options,
        count: 0,
        color: StockMarketColors.options,
        maxWidth: defaultPercentage,
        percent: "",
      },
    ];
    if (stocks) {
      var totalCount = stocks.length;
      var oneColWidth = areaWidth / totalCount;
      var oneColSpace = (oneColWidth / areaWidth) * 100;
      for (let market of totalData) {
        market.count = stocks.filter(
          (x) => x.type?.toLowerCase() == market.title?.toLowerCase()
        ).length;
        market.maxWidth = oneColSpace * market.count;
        market.percent = ((market.count / stocks.length) * 100).toFixed(2);
      }

      totalData.sort((a, b) => b.count - a.count);
    }
    return totalData;
  }

  getTradeColor(key: string) {
    if (key == "open") {
      return "#D667FF";
    } else if (key == "high") {
      return "#A3FF99";
    } else if (key == "low") {
      return "#FF8080";
    } else if (key == "close") {
      return "#FFC477";
    }
  }

  getAttributeColor(name: string) {
    var item = this.attributesList.find((x) => x.name == name);
    if (item) {
      return item.color;
    }
  }

  benchmarkingDummyData: Object = {
    _id: "1703177802499",
    modifiedBy: "",
    modifiedOn: 1703630831655,
    name: "Benchmarking",
    order: 9,
    type: "benchmarking",
    userId: "1661449528310",
    data: {
      list: [
        {
          stockId: "TSLA",
          symbol: "Tesla, Inc.",
          name: "Tesla, Inc.",
          percentage: 728.57,
          percentageTitle: "Last 3M P&L",
          type: "portfolio",
          title: "Portfolio",
          logoUrl: "https://cdn-images.traderverse.io/stocks/TSLA_NASDAQ.png",
          tradeDate: 1703630694025,
          data: [
            {
              name: "high",
              data: [
                [1698969600000, "2335.0000"],
                [1698883200000, "2324.0000"],
                [1698796800000, "2317.5000"],
                [1698710400000, "2332.9500"],
                [1698624000000, "2324.6001"],
              ],
            },
            {
              name: "low",
              data: [
                [1698969600000, "2314.8999"],
                [1698883200000, "2306.5000"],
                [1698796800000, "2275.2500"],
                [1698710400000, "2282.8000"],
                [1698624000000, "2272.0000"],
              ],
            },
          ],
          description:
            "Commutative Profit Ratio - Cumulative Profit/Adjusted Total Beginning Assets",
        },
        {
          stockId: "NVDA",
          symbol: "NVIDIA Corporation",
          name: "NVIDIA Corporation",
          percentage: 728.57,
          percentageTitle: "Last 3M P&L",
          type: "portfolio",
          title: "Portfolio",
          logoUrl: "https://cdn-images.traderverse.io/stocks/NVDA_NASDAQ.png",
          tradeDate: 1703630694025,
          data: [
            {
              name: "high",
              data: [
                [1698969600000, "2335.0000"],
                [1698883200000, "2324.0000"],
                [1698796800000, "2317.5000"],
                [1698710400000, "2332.9500"],
                [1698624000000, "2324.6001"],
              ],
            },
            {
              name: "low",
              data: [
                [1698969600000, "2314.8999"],
                [1698883200000, "2306.5000"],
                [1698796800000, "2275.2500"],
                [1698710400000, "2282.8000"],
                [1698624000000, "2272.0000"],
              ],
            },
          ],
          description:
            "Commutative Profit Ratio - Cumulative Profit/Adjusted Total Beginning Assets",
        },
        {
          stockId: "MSFT",
          symbol: "Microsoft Corporation",
          name: "Microsoft Corporation",
          percentage: 728.57,
          percentageTitle: "Last 3M P&L",
          type: "portfolio",
          title: "Portfolio",
          logoUrl: "https://cdn-images.traderverse.io/stocks/MSFT_NASDAQ.png",
          tradeDate: 1703630694025,
          data: [
            {
              name: "high",
              data: [
                [1698969600000, "2335.0000"],
                [1698883200000, "2324.0000"],
                [1698796800000, "2317.5000"],
                [1698710400000, "2332.9500"],
                [1698624000000, "2324.6001"],
              ],
            },
            {
              name: "low",
              data: [
                [1698969600000, "2314.8999"],
                [1698883200000, "2306.5000"],
                [1698796800000, "2275.2500"],
                [1698710400000, "2282.8000"],
                [1698624000000, "2272.0000"],
              ],
            },
          ],
          description:
            "Commutative Profit Ratio - Cumulative Profit/Adjusted Total Beginning Assets",
        },
      ],
    },
  };
  predictionOHLCDummyData: Object = {
    _id: "1704129205105",
    modifiedBy: "",
    modifiedOn: 1704216238104,
    name: "Predictions OHLC",
    order: 10,
    type: "predictionsOHLC",
    userId: "1703694870502",

    data: {
      list: [
        {
          stockId: "BTC_CRYPTO",
          symbol: "BTC",
          name: "Bitcoin US",
          changesPercentage: 2.4762,
          change: 1094.16,
          purchased: {
            date: "2024-01-01T08:00:00.000Z",
            quantity: "1",
            price: "45000",
          },

          price: 142500,
          results: {
            ratio: "2/4",
            accuracy: 50,
          },
          ohlc: [
            {
              type: "open",
              name: "Open",
              price: 143.7,
              percentage: 2.45001,
              id: 10001,
            },
            {
              type: "high",
              name: "High",
              price: 151.4,
              percentage: 5.5001,
              id: 10001,
            },
            {
              type: "low",
              name: "Low",
              price: 140.2,
              percentage: -1.2,
              id: 10001,
            },
            {
              type: "close",
              name: "Close",
              price: 145.6,
              percentage: 1.7004,
              id: 10001,
            },
          ],
          type: "crypto",
          logoUrl: "https://cdn-images.traderverse.io/crypto/BTC.png",
          sold: {
            date: "2024-01-02T08:00:00.000Z",
            quantity: "1",
            price: "45001",
          },
        },
      ],
    },
  };
  dummyStocks: any[] = [
    {
      _id: "MSFT_NASDAQ",
      symbol: "MSFT",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/MSFT_NASDAQ.png",
    },
    {
      _id: "NVDA_NASDAQ",
      symbol: "NVDA",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/NVDA_NASDAQ.png",
    },
    {
      _id: "AAPL_NASDAQ",
      symbol: "AAPL",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/AAPL_NASDAQ.png",
    },
    {
      _id: "ADBE_NASDAQ",
      symbol: "ADBE",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/ADBE_NASDAQ.png",
    },
    {
      _id: "INTC_NASDAQ",
      symbol: "INTC",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/INTC_NASDAQ.png",
    },
    {
      _id: "NFLX_NASDAQ",
      symbol: "NFLX",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/NFLX_NASDAQ.png",
    },
    {
      _id: "PEP_NASDAQ",
      symbol: "PEP",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/PEP_NASDAQ.png",
    },
    {
      _id: "EBAY_NASDAQ",
      symbol: "EBAY",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/EBAY_NASDAQ.png",
    },
    {
      _id: "META_NASDAQ",
      symbol: "META",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/META_NASDAQ.png",
    },
    {
      symbol: "AMZN",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/AMZN_NASDAQ.png",
    },
    {
      symbol: "TSLA",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/TSLA_NASDAQ.png",
    },
    {
      _id: "CASY_NASDAQ",
      symbol: "CASY",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/CASY_NASDAQ.png",
    },
    {
      symbol: "AVGO",
      market: "stocks",
      logoUrl: "https://cdn-images.traderverse.io/stocks/AVGO_NASDAQ.png",
    },
  ];
  dummyTransactions: any[] = [
    {
      _id: "1690564105058",
      companyName: "Microsoft Corporation Common Stock",
      createdOn: 1690564105058,
      date: "2023-05-18",
      logoUrl: "https://cdn-images.traderverse.io/stocks/MSFT_NASDAQ.png",
      modifiedBy: "",
      modifiedOn: 1690564105059,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105058",
      quantity: 4000,
      rate: 338.79,
      remaining: 4000,
      stockType: "stocks",
      ticker: "MSFT",
      type: "buy",
      undefined: null,
    },
    {
      _id: "1690564105080",
      companyName: "Bitcoin",
      createdOn: 1690564105080,
      date: "2023-06-16",
      logoUrl: "https://cdn-images.traderverse.io/crypto/BTC.png",
      modifiedBy: "",
      modifiedOn: 1690564105080,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105080",
      quantity: 700,
      rate: 29345.92,
      remaining: 700,
      stockType: "crypto",
      ticker: "BTC",
      type: "buy",
      undefined: null,
    },
    {
      _id: "1690564105093",
      companyName: "Amazon.com, Inc. Common Stock",
      createdOn: 1690564105093,
      date: "2023-07-14",
      logoUrl: "https://cdn-images.traderverse.io/stocks/AMZN_NASDAQ.png",
      modifiedBy: "",
      modifiedOn: 1690564105094,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105093",
      quantity: 6000,
      rate: 132.31,
      remaining: 6000,
      stockType: "stocks",
      ticker: "AMZN",
      type: "buy",
      undefined: null,
    },
    {
      _id: "1690564105107",
      companyName: "Ethereum",
      createdOn: 1690564105107,
      date: "2023-06-26",
      logoUrl: "https://cdn-images.traderverse.io/crypto/ETH.png",
      modifiedBy: "",
      modifiedOn: 1690564105107,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105107",
      quantity: 5000,
      rate: 1876.09,
      remaining: 5000,
      stockType: "crypto",
      ticker: "ETH",
      type: "buy",
      undefined: null,
    },
    {
      _id: "1690564105119",
      companyName: "Apple Inc. Common Stock",
      createdOn: 1690564105119,
      date: "2023-06-23",
      logoUrl: "https://cdn-images.traderverse.io/stocks/AAPL_NASDAQ.png",
      modifiedBy: "",
      modifiedOn: 1690564105119,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105119",
      quantity: 2000,
      rate: 196.505,
      remaining: 2000,
      stockType: "stocks",
      ticker: "AAPL",
      type: "buy",
      undefined: null,
    },
    {
      _id: "1690564105133",
      companyName: "NVIDIA Corporation Common Stock",
      createdOn: 1690564105133,
      date: "2023-06-29",
      logoUrl: "https://cdn-images.traderverse.io/stocks/NVDA_NASDAQ.png",
      modifiedBy: "",
      modifiedOn: 1690564105133,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105133",
      quantity: 500,
      rate: 468.89,
      remaining: 500,
      stockType: "stocks",
      ticker: "NVDA",
      type: "buy",
      undefined: null,
    },
    {
      _id: "1690564105147",
      companyName: "Meta Platforms, Inc. Class A Common Stock",
      createdOn: 1690564105147,
      date: "2023-07-19",
      logoUrl: "https://cdn-images.traderverse.io/stocks/META_NASDAQ.png",
      modifiedBy: "",
      modifiedOn: 1690564350449,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105147",
      quantity: 3000,
      rate: 324.64,
      remaining: 1500,
      stockType: "stocks",
      ticker: "META",
      type: "buy",
      undefined: null,
    },
    {
      _id: "1690564105160",
      companyName: "Tether",
      createdOn: 1690564105160,
      date: "2023-07-28",
      logoUrl: "https://cdn-images.traderverse.io/crypto/USDT.png",
      modifiedBy: "",
      modifiedOn: 1690564350387,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105160",
      quantity: 2000,
      rate: 1,
      remaining: 1000,
      stockType: "crypto",
      ticker: "USDT",
      type: "buy",
      undefined: null,
    },
    {
      _id: "1690564105175",
      companyName: "TESLA INC",
      createdOn: 1690564105175,
      date: "2023-07-27",
      logoUrl: "https://cdn-images.traderverse.io/stocks/TSLA_XNGS.png",
      modifiedBy: "",
      modifiedOn: 1690564350374,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105175",
      quantity: 1500,
      rate: 265.9,
      remaining: 1000,
      stockType: "stocks",
      ticker: "TSLA",
      type: "buy",
      undefined: null,
    },
    {
      _id: "1690564350362",
      buyId: "1690564105175",
      companyName: "TESLA INC",
      createdOn: 1690564350362,
      date: "2023-07-27",
      logoUrl: "https://cdn-images.traderverse.io/stocks/TSLA_XNGS.png",
      modifiedBy: "",
      modifiedOn: 1690564350363,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105175",
      quantity: 500,
      rate: 267.9,
      remaining: 500,
      stockType: "stocks",
      ticker: "TSLA",
      type: "sell",
    },
    {
      _id: "1690564350380",
      buyId: "1690564105160",
      companyName: "Tether",
      createdOn: 1690564350380,
      date: "2023-07-28",
      logoUrl: "https://cdn-images.traderverse.io/crypto/USDT.png",
      modifiedBy: "",
      modifiedOn: 1690564350381,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105160",
      quantity: 1000,
      rate: 1.5,
      remaining: 1000,
      stockType: "crypto",
      ticker: "USDT",
      type: "sell",
    },
    {
      _id: "1690564350397",
      buyId: "1690564105147",
      companyName: "Meta Platforms, Inc. Class A Common Stock",
      createdOn: 1690564350397,
      date: "2023-07-25",
      logoUrl: "https://cdn-images.traderverse.io/stocks/META_NASDAQ.png",
      modifiedBy: "",
      modifiedOn: 1690564350397,
      portfolioId: "1690562028870",
      portfolioItemid: "1690564105147",
      quantity: 1500,
      rate: 323.66,
      remaining: 1500,
      stockType: "stocks",
      ticker: "META",
      type: "sell",
    },
  ];

  dummyLineItems: any[] = [
    {
      _id: "1690560117756",
      createdOn: 1690560117779,
      profit: 0,
      quantity: 100,
      rate: 132.205,
      ticker: "AMZN",
      costPrice: 132.205,
      remaining: 100,
      sellPrice: 0,
      companyName: "Amazon.com, Inc. Common Stock",
      logoUrl: "https://cdn-images.traderverse.io/stocks/AMZN_NASDAQ.png",
      stockType: "stocks",
      marketCap: 1321539600384,
      change: -0.9230746,
      originalPrice: 128.8,
    },
    {
      _id: "1690560117786",
      createdOn: 1690560117790,
      profit: 0,
      quantity: 50,
      rate: 196.23,
      ticker: "AAPL",
      costPrice: 196.23,
      remaining: 50,
      sellPrice: 0,
      companyName: "Apple Inc. Common Stock",
      logoUrl: "https://cdn-images.traderverse.io/stocks/AAPL_NASDAQ.png",
      stockType: "stocks",
      marketCap: 3031706894336,
      change: 0.4220056,
      originalPrice: 192.75,
    },
    {
      _id: "1690560117798",
      createdOn: 1690560117803,
      profit: 0,
      quantity: 150,
      rate: 133.55,
      ticker: "GOOGL",
      costPrice: 133.55,
      remaining: 150,
      sellPrice: 0,
      companyName: "ALPHABET INC",
      logoUrl: "https://cdn-images.traderverse.io/stocks/GOOGL_XNGS.png",
      stockType: "stocks",
      marketCap: 1274837729280,
      change: -7.683014,
      originalPrice: 99.37,
    },
    {
      _id: "1690560117809",
      createdOn: 1690560117814,
      profit: 0,
      quantity: 100,
      rate: 324.59,
      ticker: "META",
      costPrice: 324.59,
      remaining: 100,
      sellPrice: 0,
      companyName: "Meta Platforms, Inc. Class A Common Stock",
      logoUrl: "https://cdn-images.traderverse.io/stocks/META_NASDAQ.png",
      stockType: "stocks",
      marketCap: 747317624832,
      change: -0.90057236,
      originalPrice: 291.61,
    },
    {
      _id: "1690560117820",
      createdOn: 1690560117825,
      profit: 0,
      quantity: 200,
      rate: 264.58,
      ticker: "TSLA",
      costPrice: 264.58,
      remaining: 200,
      sellPrice: 0,
      companyName: "TESLA INC",
      logoUrl: "https://cdn-images.traderverse.io/stocks/TSLA_XNGS.png",
      stockType: "stocks",
      marketCap: 636901654528,
      change: 2.276305,
      originalPrice: 201.29,
    },
    {
      _id: "1690560117838",
      createdOn: 1690560117844,
      profit: 0,
      quantity: 150,
      rate: 337.4339,
      ticker: "MSFT",
      costPrice: 337.4339,
      remaining: 150,
      sellPrice: 0,
      companyName: "Microsoft Corporation Common Stock",
      logoUrl: "https://cdn-images.traderverse.io/stocks/MSFT_NASDAQ.png",
      stockType: "stocks",
      marketCap: 2566061817856,
      change: 0.38979444,
      originalPrice: 345.11,
    },
    {
      _id: "1690560117856",
      createdOn: 1690560840727,
      profit: -827.5999999999985,
      quantity: 50,
      rate: 469.39,
      ticker: "NVDA",
      costPrice: 469.39,
      remaining: 50,
      sellPrice: 452.838,
      companyName: "NVIDIA Corporation Common Stock",
      logoUrl: "https://cdn-images.traderverse.io/stocks/NVDA_NASDAQ.png",
      stockType: "stocks",
    },
    {
      _id: "1690560536028",
      createdOn: 1690560840704,
      profit: 38295.79999999993,
      quantity: 30,
      rate: 29368.59,
      ticker: "BTC",
      costPrice: 29368.59,
      remaining: 30,
      sellPrice: 31283.379999999997,
      companyName: "Bitcoin",
      logoUrl: "https://cdn-images.traderverse.io/crypto/BTC.png",
      stockType: "crypto",
    },
    {
      _id: "1690560536054",
      createdOn: 1690560840668,
      profit: -10000,
      quantity: 50,
      rate: 1874.28,
      ticker: "ETH",
      costPrice: 1874.28,
      remaining: 50,
      sellPrice: 1674.28,
      companyName: "Ethereum",
      logoUrl: "https://cdn-images.traderverse.io/crypto/ETH.png",
      stockType: "crypto",
    },
    {
      _id: "1690560536067",
      createdOn: 1690560840637,
      profit: 2000.2,
      quantity: 2000,
      rate: 0.9999,
      ticker: "USDT",
      costPrice: 0.9999,
      remaining: 2000,
      sellPrice: 2,
      companyName: "Tether",
      logoUrl: "https://cdn-images.traderverse.io/crypto/USDT.png",
      stockType: "crypto",
    },
    {
      _id: "1690560536079",
      createdOn: 1690560840603,
      profit: 500,
      quantity: 1000,
      rate: 1,
      ticker: "USDCE",
      costPrice: 1,
      remaining: 1000,
      sellPrice: 1.5,
      companyName: "USD Coin Bridged",
      logoUrl: "https://cdn-images.traderverse.io/crypto/USDCE.png",
      stockType: "crypto",
    },
  ];

  attributesList: any[] = [
    {
      name: "Fundamental Trading",
      color: "#FDF621",
    },
    {
      name: "Technical Trading",
      color: "#43FF6E",
    },
    {
      name: "Scalping",
      color: "#FF895A",
    },
    {
      name: "Position Trading",
      color: "#6FFCD0",
    },
    {
      name: "Arbitrage Trading",
      color: "#B87BFF",
    },
    {
      name: "Noise Trading",
      color: "rgb(235 160 16)",
    },
    {
      name: "Sentiment Trading",
      color: "#4BB4FF",
    },
    {
      name: "Intraday Trading",
      color: "#FCF6CB",
    },
    {
      name: "Price Action Trading",
      color: "#FC8383",
    },
    {
      name: "Day Trading",
      color: "#FF71CD",
    },
    {
      name: "Swing Trading",
      color: "#FF3B3B",
    },
    {
      name: "Contrarian Trading",
      color: "#DCBEFF",
    },
    {
      name: "Market Timing",
      color: "#00D068",
    },
    {
      name: "Algorithmic Trading",
      color: "#00BCD4",
    },
    {
      name: "Momentum Trading",
      color: "rgb(152 138 111)",
    },
  ];

  portfolioAccounts: any[] = [
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "binance.png",
      title: "My Portfolio 1",
      category: "Binance",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#C48D05",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "gate.png",
      title: "My Portfolio 2",
      category: "Gate.io",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#25C791",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "crypto.png",
      title: "My Portfolio 3",
      category: "Crypto",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#03316C",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "metamask.png",
      title: "My Portfolio 4",
      category: "Metamask",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#E4761B",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "kraken.png",
      title: "My Portfolio 5",
      category: "Kraken",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#5741D9",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "blockfi.png",
      title: "My Portfolio 6",
      category: "BlockFi",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#004BEF",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "exodus.png",
      title: "My Portfolio 7",
      category: "Exodus",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#8B63FC",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "gemini.png",
      title: "My Portfolio 8",
      category: "Gemini",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#17BBD5",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "coinbase.png",
      title: "My Portfolio 9",
      category: "Coinbase ",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#0052FF",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "nanoledger.png",
      title: "My Portfolio 10",
      category: "Nano Ledger",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "var(--card-inner-color-profile)",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "ftx.png",
      title: "My Portfolio 11",
      category: "FTX.US",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#1297B2",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "electrum.png",
      title: "My Portfolio 12",
      category: "Electrum",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#0056C0",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "trezon.png",
      title: "My Portfolio 13",
      category: "Trezon",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "var(--card-inner-color-profile)",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "trustwallet.png",
      title: "My Portfolio 14",
      category: "Trust Wallet",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#3375BB",
    },
    {
      directory: "portfolio_wallets",
      type: "Exchanges",
      image: "hyperledger.png",
      title: "My Portfolio 14",
      category: "HyperLedger ",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#2F3135",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "etrade.png",
      title: "ETrade",
      category: "Etrade",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#99b138",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "ally.png",
      title: "My Portfolio 1",
      category: "Ally Invest",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#651061",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "ameri.png",
      title: "My Portfolio 2",
      category: "Ameritrade",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#0c4636",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "charles.png",
      title: "My Portfolio 3",
      category: "Charles Schwab",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#0d9ed7",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "chase.png",
      title: "My Portfolio 4",
      category: "Chase",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#177cbc",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "fidelity.png",
      title: "My Portfolio 5",
      category: "Fidelity",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#d38c0d",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "firsttrader.png",
      title: "My Portfolio 6",
      category: "First Trader",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#33578e",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "interactive.png",
      title: "My Portfolio 7",
      category: "Interactive Broker",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#ac1a27",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "merilllynch.png",
      title: "My Portfolio 8",
      category: "Merrill Lynch",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#0e256c",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "robinhood.png",
      title: "My Portfolio 9",
      category: "Robinhood",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#479f49",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "tradestation.png",
      title: "My Portfolio 10",
      category: "Tradestation",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#1eada1",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "vanguard.png",
      title: "My Portfolio 11",
      category: "Vanguard",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#962027",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "webull.png",
      title: "My Portfolio 12",
      category: "Webull",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#0c4adf",
    },
    {
      directory: "portfolio_brokerage",
      type: "Brokerage",
      image: "wells.png",
      title: "My Portfolio 13",
      category: "Wells Fargo",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#d12832",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "binance.png",
      title: "My Portfolio 1",
      category: "Binance",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#C48D05",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "gate.png",
      title: "My Portfolio 2",
      category: "Gate.io",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#25C791",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "crypto.png",
      title: "My Portfolio 3",
      category: "Crypto",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#03316C",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "metamask.png",
      title: "My Portfolio 4",
      category: "Metamask",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#E4761B",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "kraken.png",
      title: "My Portfolio 5",
      category: "Kraken",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#5741D9",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "blockfi.png",
      title: "My Portfolio 6",
      category: "BlockFi",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#004BEF",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "exodus.png",
      title: "My Portfolio 7",
      category: "Exodus",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#8B63FC",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "gemini.png",
      title: "My Portfolio 8",
      category: "Gemini",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#17BBD5",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "coinbase.png",
      title: "My Portfolio 9",
      category: "Coinbase ",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#0052FF",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "nanoledger.png",
      title: "My Portfolio 10",
      category: "Nano Ledger",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "var(--card-inner-color-profile)",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "ftx.png",
      title: "My Portfolio 11",
      category: "FTX.US",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#1297B2",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "electrum.png",
      title: "My Portfolio 12",
      category: "Electrum",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#0056C0",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "trezon.png",
      title: "My Portfolio 13",
      category: "Trezon",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "var(--card-inner-color-profile)",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "trustwallet.png",
      title: "My Portfolio 14",
      category: "Trust Wallet",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#3375BB",
    },
    {
      directory: "portfolio_wallets",
      type: "Wallets",
      image: "hyperledger.png",
      title: "My Portfolio 14",
      category: "HyperLedger ",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#2F3135",
    },
    {
      directory: "portfolio_payment",
      image: "amazonpay.png",
      title: "Simon Lewis",
      type: "Payment",
      category: "Amazon Pay",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: false,
      color: "#DD8D00",
    },
    {
      directory: "portfolio_payment",
      image: "stripe.png",
      title: "Simon Lewis",
      category: "Stripe",
      balance: 73777.99,
      type: "Payment",
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#6760f5",
    },
    {
      directory: "portfolio_payment",
      image: "block.png",
      title: "Simon Lewis",
      category: "Block",
      balance: 73777.99,
      stocks: 0,
      type: "Payment",
      crypto: 0,
      isConnected: true,
      color: "#14AFA3",
    },
    {
      directory: "portfolio_payment",
      image: "paypal.png",
      title: "Simon Lewis",
      category: "PayPal",
      balance: 73777.99,
      type: "Payment",
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#003085",
    },
    {
      directory: "portfolio_payment",
      type: "Payment",
      image: "apple.png",
      title: "Simon Lewis",
      category: "Apple Pay",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "var(--card-inner-color-profile)",
    },
    {
      directory: "portfolio_payment",
      type: "Payment",
      image: "googlewallet.png",
      title: "Simon Lewis",
      category: "Google Wallet",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#f5342e",
    },
    {
      directory: "portfolio_payment",
      type: "Payment",
      image: "coingate.png",
      title: "Simon Lewis",
      category: "Coin Gate",
      balance: 73777.99,
      stocks: 0,
      crypto: 0,
      isConnected: true,
      color: "#562ce6",
    },
    // {
    //   directory: "portfolio_accounts",
    //   image: "banking-1.png",
    //   title: "Fidelity",
    //   type: "Banking",
    //   balance: 73777.99,
    //   stocks: 0,
    //   crypto: 0,
    //   isConnected: false,
    //   color: "#0a4c90",
    // },
    // {
    //   directory: "portfolio_accounts",
    //   image: "banking-2.png",
    //   title: "Chase",
    //   type: "Banking",
    //   balance: 73777.99,
    //   color: "#0c66b5",
    //   stocks: 0,
    //   crypto: 0,
    //   isConnected: true,
    // },
    // {
    //   directory: "portfolio_accounts",
    //   image: "banking-3.png",
    //   type: "Banking",
    //   title: "Ameritrade",
    //   balance: 73777.99,
    //   stocks: 0,
    //   crypto: 0,
    //   isConnected: true,
    //   color: "#f1832f",
    // },
    // {
    //   directory: "portfolio_accounts",
    //   image: "banking-4.png",
    //   title: "Chase",
    //   balance: 73777.99,
    //   type: "Banking",
    //   stocks: 0,
    //   crypto: 0,
    //   isConnected: true,
    //   color: "#481f82",
    // },
    // {
    //   directory: "portfolio_accounts",
    //   image: "banking-5.png",
    //   title: "Fidelity",
    //   balance: 73777.99,
    //   stocks: 0,
    //   type: "Banking",
    //   crypto: 0,
    //   isConnected: true,
    //   color: "#d52630",
    // },
  ];

  StatsList = [
    { label: "Followers", value: 0, key: "followersCount" },
    { label: "Following", value: 0, key: "followingCount" },
    { label: "Friends", value: 0, key: "friendCount" },
    { label: "Investment #", value: 0, key: "investmentsCount" },
    { label: "Investment $", value: 0, key: "investments" },
    { label: "# Of Trades", value: 0, key: "trades" },
    { label: "Trades $", value: 0, key: "tradesPrice" },
    { label: "Avg Gain $", value: 0, key: "avgGainPrice" },
    { label: "Avg % Gain", value: 0, key: "avgGain" },
    { label: "Top Gain $", value: 0, key: "topGainPrice" },
    { label: "Top % Gain", value: 0, key: "topGain" },
    { label: "Avg TR/Month", value: 0, key: "avgTradesPerMonth" },
    { label: "Avg TR/Week", value: 0, key: "avgTradesPerWeek" },
    { label: "Views", value: 0, key: "viewsCount" },
    { label: "Outreach", value: 0, key: "outreach" },
    { label: "Monthly Trades", value: 0, key: "tradesThisMonth" },
    { label: "Weekly Trades", value: 0, key: "tradesThisWeek" },
    { label: "Yearly Trades", value: 0, key: "tradesThisYear" },
    { label: "Daily Trades", value: 0, key: "todayTrades" },
    { label: "Trader Type", value: 0, key: "traderTypeCount" },
    { label: "Best Trades", value: 0, key: "bestTradeCount" },
    { label: "Favorite Platforms", value: 0, key: "favoritePlatformCount" },
    { label: "Recommendations", value: 0, key: "recommendationCount" },
    { label: "Watchlists", value: 0, key: "watchListCount" },
    { label: "Lists", value: 0, key: "listCount" },
    { label: "Links", value: 0, key: "linksCount" },
    { label: "Images", value: 0, key: "imagesCount" },
    { label: "Favorite Influencers", value: 0, key: "favoriteInfluencerCount" },
    { label: "Favorite Investments", value: 0, key: "favoriteInvestmentCount" },
    { label: "Playbooks", value: 0, key: "playbookCount" },
    { label: "Profit And Loss", value: 0, key: "profitAndLossCount" },
    { label: "Predictions", value: 0, key: "predictionCount" },
    { label: "Social Links", value: 0, key: "socialLnksCount" },
  ];

  widgetsWidth: any = {
    flex: "0 0 433px",
    maxWidth: "433px",
  };

  getStatLabel(stat: any) {
    if (!stat.key) {
      stat.key = this.getStatKey(stat.label);
    }
    var ind = this.StatsList.findIndex((x) => x.key == stat.key);
    if (ind == -1) {
      return stat.label;
    } else {
      return this.StatsList[ind].label;
    }
  }

  getStatKey(label: string) {
    if (label == "Followers") {
      return "followersCount";
    } else if (label == "Following") {
      return "followingCount";
    } else if (label == "Friends") {
      return "friendCount";
    } else if (label == "Investment #") {
      return "investmentsCount";
    } else if (label == "Investment $" || label == "Investments") {
      return "investments";
    } else if (label == "Trades #" || label == "Invested") {
      return "trades";
    } else if (label == "Trades $") {
      return "tradesPrice";
    } else if (label == "Avg Gain $") {
      return "avgGainPrice";
    } else if (
      label == "Avg Gain %" ||
      label == "Avg % Gain" ||
      label == "Avg Gains"
    ) {
      return "avgGain";
    } else if (label == "Top Gain $") {
      return "topGainPrice";
    } else if (
      label == "Top Gain %" ||
      label == "Top % Gain" ||
      label == "Top Gains"
    ) {
      return "topGain";
    } else if (label == "TR/Month" || label == "Avg TR/Month") {
      return "avgTradesPerMonth";
    } else if (label == "TR/Week" || label == "Avg TR/Week") {
      return "avgTradesPerWeek";
    } else if (label == "Views") {
      return "viewsCount";
    } else if (label == "Today Trades" || label == "Daily Trades") {
      return "todayTrades";
    } else if (label == "Trades This Month" || label == "Monthly Trades") {
      return "tradesThisMonth";
    } else if (label == "Trades This Week" || label == "Weekly Trades") {
      return "tradesThisWeek";
    } else if (label == "Trades This Year" || label == "Yearly Trades") {
      return "tradesThisYear";
    } else if (label == "Outreach") {
      return "outreach";
    } else if (label == "Trader Type") {
      return "traderTypeCount";
    } else if (label == "Best Trades") {
      return "bestTradeCount";
    } else if (label == "Favorite Platforms") {
      return "favoritePlatformCount";
    } else if (label == "Recommendations") {
      return "recommendationCount";
    } else if (label == "Watchlists") {
      return "watchListCount";
    } else if (label == "Lists") {
      return "listCount";
    } else if (label == "Links") {
      return "linksCount";
    } else if (label == "Images") {
      return "imagesCount";
    } else if (label == "Favorite Influencers") {
      return "favoriteInfluencerCount";
    } else if (label == "Favorite Investments") {
      return "favoriteInvestmentCount";
    } else if (label == "Playbooks") {
      return "playbookCount";
    } else if (label == "Profit And Loss") {
      return "profitAndLossCount";
    } else if (label == "Predictions") {
      return "predictionCount";
    } else if (label == "Social Links") {
      return "socialLnksCount";
    }
  }

  hexToRgb(hex: string) {
    // Remove the leading # if present
    hex = hex.replace(/^#/, "");

    // Convert shorthand hex color to full format
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map(function (s) {
          return s + s;
        })
        .join("");
    }

    // Parse hexadecimal color to RGB
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return `${r}, ${g}, ${b}`;
  }

  onModalOpen(component: any, data: any = {}) {
    return new Promise<any>(async (resolve, reject) => {
      // this.disableScroll();
      var modal = await openModal(component, data);
      modal.onclose = () => {
        // this.enableScroll();
      };
      resolve(modal);
    });
  }
  scrollTop: number = 0;

  enableScroll() {
    var el: any = document.querySelector("#app");
    document.body.style.overflow = "auto";
    if (el) {
      el.style.overflow = "visible";
    }
    if (this.scrollTop > 0) {
      document.body.scrollTo(0, this.scrollTop);
      el.scrollTo(0, this.scrollTop);
    }
  }

  disableScroll() {
    this.scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    document.body.style.overflow = "hidden";
    var el: any = document.querySelector("#app");
    if (el) {
      el.style.overflow = "hidden";
    }
  }

  removeElementsByClassName(className: string) {
    var elements: any = document.getElementsByClassName(className);
    if (elements && elements.length) {
      var elementToRemove: any = elements[elements.length - 1];
      elementToRemove.parentNode.removeChild(elementToRemove);
    }
  }
  removeElementById(id: string) {
    var elementToRemove: any = document.getElementById(id);
    if (elementToRemove) {
      elementToRemove.parentNode.removeChild(elementToRemove);
    }
  }

  getExecutiveImage = (isMr: boolean) => {
    var assetName = "dummy_ms";
    if (isMr) {
      assetName = "dummy_mr";
    }
    return `https://firebasestorage.googleapis.com/v0/b/${this.FirebaseConfig.storageBucket}/o/assets%2F${assetName}.png?alt=media`;
  };

  // Feed Work //

  public margin: number = 25;
  private readCount: number = 0;
  widthOfCol: number = 450;

  calculateConfigurationFromFeed(
    elemId: string,
    configuration: ConfigurationModel = new ConfigurationModel(),
    isShimmerEnable: boolean = false,
    isUpdate: boolean = false
  ) {
    var el: any = document.getElementById(elemId);
    if (window.innerWidth < 1600) {
      this.widthOfCol = 360;
      this.margin = 17;
    } else {
      this.widthOfCol = 450;
      this.margin = 25;
    }
    if (el) {
      const className = "lay_col";
      if (el.clientWidth < 768) {
        el.classList.add(className);
        configuration = new ConfigurationModel(1, 1, "100%");
        isShimmerEnable = false;
      } else {
        var frameWidth = el.offsetWidth;
        // if (frameWidth <= 1200 + this.margin) {
        //   configuration = new ConfigurationModel(1, 1, "100%");
        //   el.classList.add(className);
        // } else {
        // configuration.widgetsCount = 0;
        el.classList.remove(className);

        configuration = this.checkWidth(
          configuration,
          isShimmerEnable,
          frameWidth,
          this.margin,
          isUpdate
        );
        // }
      }
    }
    if (
      configuration.widgetsWidth == "0px" ||
      configuration.activitiesWidth == "0px"
    ) {
      configuration = this.calculateConfigurationFromFeed(
        elemId,
        configuration,
        isShimmerEnable
      );
    }
    return configuration;
  }

  checkWidth(
    configuration: ConfigurationModel,
    isShimmerEnable: boolean,
    screenWidth: number,
    margin: number,
    isUpdate: boolean = false
  ): ConfigurationModel {
    var activitiesCount = configuration.activitiesCount!;
    var widgetsCount = configuration.widgetsCount!;
    var showWidgets = configuration.showWidgets ?? true;
    var showTimeline = configuration.showTimeline;
    var isChange: boolean = false;
    // var borderWidth = 6;
    // if (screenWidth < 2000) {
    //   this.widthOfCol = 350
    // }
    // if (screenWidth < 1600) {
    //   this.widthOfCol = 300
    // }
    // if (screenWidth < 1400) {
    //   this.widthOfCol = 200
    // }
    // if(screenWidth >= 2000 && screenWidth < 1260) {
    //   this.widthOfCol = 450
    // }
    var widgetsSectionWidth =
      margin * 2 + widgetsCount * this.widthOfCol + margin * (widgetsCount - 1);
    // widgetsCount * this.widthOfCol  + (margin * (widgetsCount - 1));
    // +
    // borderWidth * widgetsCount;
    var activitiesSectionWidth =
      activitiesCount * this.widthOfCol +
      margin * configuration.activitiesCount -
      margin;
    var screenUsage = activitiesSectionWidth + widgetsSectionWidth;
    if (screenWidth < screenUsage) {
      if (activitiesCount > 1) {
        activitiesCount--;
        isChange = true;
      } else if (widgetsCount > 1) {
        widgetsCount--;
        isChange = true;
      }
    }
    var totalColumns = activitiesCount + widgetsCount;
    var calcMaxWidth = screenWidth / totalColumns - (totalColumns - 2) * margin;
    if (calcMaxWidth > 700) {
      calcMaxWidth = 700;
    }
    var maxWidth = `${calcMaxWidth}px`;
    configuration = new ConfigurationModel(
      activitiesCount,
      widgetsCount,
      maxWidth,
      totalColumns,
      showWidgets,
      showTimeline
    );
    if (isChange) {
      return this.checkWidth(
        configuration,
        isShimmerEnable,
        screenWidth,
        margin
      );
    } else {
      activitiesSectionWidth = screenWidth - widgetsSectionWidth - margin * 2;
      if (activitiesSectionWidth < 700 && widgetsCount > 1) {
        widgetsCount--;
        activitiesCount++;
      }
      var widgetsExtraWidth = 14;
      widgetsSectionWidth =
        margin * 2 +
        widgetsCount * this.widthOfCol +
        margin * (widgetsCount - 1) +
        widgetsExtraWidth;
      // widgetsSectionWidth =
      //   widgetsCount * this.widthOfCol + widgetsExtraWidth + margin * widgetsCount + 6;
      activitiesSectionWidth = screenWidth - widgetsSectionWidth - margin * 2;
      if (isUpdate || screenWidth >= 2000 || screenWidth < 1260) {
        configuration.widgetsCount = widgetsCount;
        configuration.activitiesCount = activitiesCount;
        configuration.totalColumns = widgetsCount + activitiesCount;
      } else {
        configuration.widgetsCount = 1;
        configuration.activitiesCount = 3;
        configuration.totalColumns = 4;
      }

      configuration.widgetsWidth = widgetsSectionWidth + "px";
      configuration.activitiesWidth = activitiesSectionWidth + "px";
      configuration.showWidgets = showWidgets;
      configuration.showTimeline = showTimeline;
      if (isShimmerEnable == true) {
        setTimeout(() => {
          isShimmerEnable = false;
        }, 2500);
      }
      return configuration;
    }
  }
  isSmallScreen() {
    if (!window) return false;
    let isSmall = window.innerWidth < 1600;
    const handleResize = () => {
      isSmall = window.innerWidth < 1600;
    };
    window.addEventListener("resize", handleResize);
    return isSmall;
  }
  getStockCDNImage = (
    logoUrl: string,
    stockId: string,
    type: StockMarkets | string = StockMarkets.stocks
  ) => {
    if (
      logoUrl &&
      (logoUrl.includes("cdn-images.traderverse.io") ||
        (logoUrl.includes("firebasestorage.googleapis") &&
          logoUrl.includes(this.FirebaseConfig.storageBucket)))
    ) {
      return logoUrl;
    } else {
      if (stockId) {
        if (type == StockMarkets.stocks) {
          return `https://cdn-images.traderverse.io/stocks/${stockId
            .split(" ")
            .join("%")}.png`;
        } else {
          return `https://cdn-images.traderverse.io/crypto/${stockId
            .split(" ")
            .join("%")}.png`;
        }
      } else {
        if (type == StockMarkets.stocks) {
          return `https://firebasestorage.googleapis.com/v0/b/${this.FirebaseConfig.storageBucket}/o/images%2Fdummy-stock.png?alt=media`;
        }
        return `https://firebasestorage.googleapis.com/v0/b/${this.FirebaseConfig.storageBucket}/o/images%2Fdummy-crypto.png?alt=media`;
      }
    }
  };

  chartTheme: any = {
    light: {
      "--text-alt-color": "#999b9f",
      "--card-inner-color-profile": "#eaeaef",
      "--chart-legend": "#1d2733",
      "--card-background-color": "#fff",
      "--card-tile-color": "#fff",
      "--text-color": "#1d2733",
    },
    default: {
      "--text-alt-color": "#5f4c9c",
      "--card-inner-color-profile": "#181326",
      "--chart-legend": "#ffffff4d",
      "--card-background-color": "#1e1a30",
      "--card-tile-color": "#25203c",
      "--text-color": "#fff",
    },
  };

  getStockCryptoByKeyword = async (keyword: string) => {
    const { searchStocks } = useStockSocket();
    if (keyword.length) {
      const response: any = await searchStocks(keyword);
      if (response && response.length) {
        return response.map((x: any) => {
          var user = new GeneralStockModel();
          user = Object.assign(user, x);
          return user;
        });
      }
    }
    return [];
  };

  getUserByKeyword = async (keyword: string) => {
    if (keyword.length) {
      const response: any = await httpService.getUsers(keyword);
      if (
        response.data.isSuccess &&
        response.data.data &&
        response.data.data.length
      ) {
        return response.data.data.map((x: any) => {
          var user = new GeneralUserModel();
          user = Object.assign(user, x);
          return user;
        });
      }
    }
    return [];
  };

  updateReadCount() {
    this.readCount = storageService.GetProperty("readCount");
    if (!this.readCount) {
      this.readCount = 0;
    }
    this.readCount++;
    storageService.SetProperty("readCount", this.readCount);
  }

  appCategories = [
    { label: "Nasdaq", value: "nasdaq" },
    { label: "OTC", value: "otc" },
    { label: "Crypto", value: "crypto" },
    { label: "Stocks", value: "stocks" },
    { label: "Forex", value: "forex" },
    { label: "NFTs", value: "nft" },
  ];

  getColumnWidth(area: any, count: number): Promise<string> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (area != null) {
          var areaWidth = area["clientWidth"];
          var postTotalMargin = this.margin * (count - 1);
          var maxWidth = (areaWidth - postTotalMargin) / count;
          if (maxWidth > 700) {
            maxWidth = 700;
          }
          resolve(`${maxWidth}px`);
        }
        resolve("600px");
      }, 300);
    });
  }

  getUserFromPost = (post: PostsModel) => {
    var user = new User();
    if (post) {
      user._id = post.userId;
      user.fullName = post.fullName;
      user.country = post.userCountry;
      user.userName = post.userName;
      user.profilePicture = this.getUserProfilePicture(
        post.userId!,
        post.profilePicture!,
        post.userGender!
      );
      user.coverPhoto = this.getUserProfilePicture(
        post.userId!,
        post.coverPhoto!,
        post.userGender!,
        true
      );
    }
    return user;
  };

  convertHTMLToPlainText(html: string) {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  toolbarOptions = [
    [{ header: [1, 2, 3] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ 'font': [] }],
    ["clean"],
  ];

  getFeelingsJSON() {
    return feelingsJSON;
  }

  getAxios = (url: string, contentType: string = "application/json") => {
    return axios.create({
      baseURL: url,
    });
  };

  getMetaDataObject = (object: any) => {
    if (
      object["og:description"] ||
      object["description"] ||
      object["og:site_name"] ||
      object["source"] ||
      object["ogv-tooltip"] ||
      object["title"] ||
      object["og:image"] ||
      object["image"] ||
      object["og:url"] ||
      object["url"]
    ) {
      var meta = {
        description:
          object["og:description"] != ""
            ? object["og:description"]
            : object["description"],
        siteName:
          object["og:site_name"] != ""
            ? object["og:site_name"]
            : object["source"],
        title:
          object["ogv-tooltip"] != "" ? object["ogv-tooltip"] : object["title"],
        image: object["og:image"] != "" ? object["og:image"] : object["image"],
        url: object["og:url"] != "" ? object["og:url"] : object["url"],
      };
      if (meta.image && !meta.image.includes("http")) {
        meta.image = null;
      }
      return meta;
    }
    return null;
  };

  status = [
    { name: "Open", value: "Open", color: "#8B77CF", isActive: true },
    { name: "Close", value: "Close", color: "#FB267B", isActive: false },
    { name: "High", value: "High", color: "#ffb21e", isActive: false },
    { name: "Low", value: "Low", color: "#FF3B3B", isActive: false },
  ];

  getChartType(val: string, market: string) {
    if (market) {
      market = market.toLowerCase();
    }
    var chartType: any = JSON.parse(
      JSON.stringify(ChartTypesJSON.find((x) => x.value == val))
    );
    if (market == StockMarkets.crypto) {
      chartType.apiUrl = chartType.apiUrl.replace(
        "widgetconfiguration",
        "crypto"
      );
    }
    return chartType;
  }

  readonly chartDuartions = [
    "1d",
    "5d",
    "1mo",
    "3mo",
    "6mo",
    "1y",
    // "2y",
    // "5y",
    // "10y",
    // "ytd",
    // "max",
  ];

//   [
//     1Day		5Minutes
//     5Day		30Minutes
//     1Month		1Hour
//     3Month		4Hour
//     6Month		1Day
//     1Year		1 Wk
// ]

  readonly intervals = [
    // { value: "1m", durations: ["1d"] },
    // { value: "2m", durations: ["1d"] },
    { value: "1h", durations: ["1d"] },
    { value: "30m", durations: ["5d"] },
    { value: "1d", durations: ["1mo"] },
    { value: "4h", durations: ["3mo"] },
    { value: "1d", durations: ["6mo"] },
    { value: "1wk", durations: ["1y"] },
    // { value: "15m", durations: ["1d", "5d"] },
    // { value: "30m", durations: ["1d", "5d"] },
    // { value: "60m", durations: ["1d", "5d"] },
    // { value: "1h", durations: ["1d", "5d"] },
    // { value: "1d", durations: ["5d", "1mo", "3mo", "6mo", "1y", "2y", "ytd"] },
    // { value: "5d", durations: [] },
    // {
    //   value: "1wk",
    //   durations: ["1mo", "3mo", "6mo", "1y", "2y", "5y", "10y", "ytd"],
    // },
    // { value: "1mo", durations: ["6mo", "1y", "2y", "5y", "10y", "ytd", "max"] },
    // { value: "3mo", durations: ["6mo", "1y", "2y", "5y", "10y", "ytd", "max"] },
  ];

  readonly chartDataTypes = ["Open", "High", "Low", "Close", "Volume"];

  getChartQuery = (
    symbol: string,
    duration: string = "",
    interval: string = "",
    dataType: any[] = []
  ) => {
    var query = "?";
    if (symbol) {
      query += `symbol=${symbol}`;
    }
    if (duration) {
      query += `&duration=${duration}`;
    }
    if (interval) {
      query += `&interval=${interval}`;
    }
    if (dataType.length) {
      query += `&dataType=${dataType
        .filter((x) => x.isActive)
        .map((x) => x.value)
        .join()}`;
    }
    return query;
  };

  getChartData(type: string) {
    if (type == "candlestick") {
      return [
        {
          data: [
            [
              1657200600000,
              [
                265.1199951171875, 269.05499267578125, 265.0199890136719,
                268.80999755859375,
              ],
            ],
            [
              1657204200000,
              [
                268.82000732421875, 268.9241943359375, 267.29998779296875,
                267.3599853515625,
              ],
            ],
            [
              1657207800000,
              [
                267.364990234375, 268.17498779296875, 267.04998779296875,
                267.7149963378906,
              ],
            ],
            [
              1657211400000,
              [
                267.7200012207031, 268.0400085449219, 267.260009765625,
                267.4150085449219,
              ],
            ],
            [
              1657215000000,
              [
                267.3999938964844, 268.3399963378906, 267.29998779296875,
                268.32000732421875,
              ],
            ],
            [
              1657218600000,
              [
                268.32000732421875, 268.9800109863281, 267.6400146484375,
                268.3399963378906,
              ],
            ],
            [
              1657222200000,
              [
                268.3301086425781, 268.7699890136719, 267.8399963378906,
                268.44000244140625,
              ],
            ],
            [
              1657224000000,
              [
                268.3999938964844, 268.3999938964844, 268.3999938964844,
                268.3999938964844,
              ],
            ],
          ],
        },
      ];
    }
    return [
      {
        name: "Open",
        data: [
          { x: 1657200600000, y: 265.1199951171875 },
          { x: 1657204200000, y: 268.82000732421875 },
          { x: 1657207800000, y: 267.364990234375 },
          { x: 1657211400000, y: 267.7200012207031 },
          { x: 1657215000000, y: 267.3999938964844 },
          { x: 1657218600000, y: 268.32000732421875 },
          { x: 1657222200000, y: 268.3301086425781 },
          { x: 1657224000000, y: 268.3999938964844 },
        ],
      },
      {
        name: "High",
        data: [
          { x: 1657200600000, y: 269.05499267578125 },
          { x: 1657204200000, y: 268.9241943359375 },
          { x: 1657207800000, y: 268.17498779296875 },
          { x: 1657211400000, y: 268.0400085449219 },
          { x: 1657215000000, y: 268.3399963378906 },
          { x: 1657218600000, y: 268.9800109863281 },
          { x: 1657222200000, y: 268.7699890136719 },
          { x: 1657224000000, y: 268.3999938964844 },
        ],
      },
      {
        name: "Low",
        data: [
          { x: 1657200600000, y: 265.0199890136719 },
          { x: 1657204200000, y: 267.29998779296875 },
          { x: 1657207800000, y: 267.04998779296875 },
          { x: 1657211400000, y: 267.260009765625 },
          { x: 1657215000000, y: 267.29998779296875 },
          { x: 1657218600000, y: 267.6400146484375 },
          { x: 1657222200000, y: 267.8399963378906 },
          { x: 1657224000000, y: 268.3999938964844 },
        ],
      },
      {
        name: "Close",
        data: [
          { x: 1657200600000, y: 268.80999755859375 },
          { x: 1657204200000, y: 267.3599853515625 },
          { x: 1657207800000, y: 267.7149963378906 },
          { x: 1657211400000, y: 267.4150085449219 },
          { x: 1657215000000, y: 268.32000732421875 },
          { x: 1657218600000, y: 268.3399963378906 },
          { x: 1657222200000, y: 268.44000244140625 },
          { x: 1657224000000, y: 268.3999938964844 },
        ],
      },
    ];
  }

  getStock() {
    return {
      key: {
        symbol: "MSFT",
        exchange: "NGS",
        exLgName: "Nasdaq Global Select",
        exShName: "NGS",
      },
      equityinfo: { longname: "Microsoft Corporation", shortname: "MSFT" },
      status: {},
      pricedata: {
        last: 268.4,
        change: 2.19,
        changepercent: 0.822659,
        tick: 0,
        open: 265.12,
        high: 269.055,
        low: 265.02,
        prevclose: 266.21,
        bid: 267.78,
        ask: 268,
        bidsize: 200,
        asksize: 2100,
        rawbidsize: 2,
        rawasksize: 21,
        tradevolume: 279382,
        sharevolume: 20839923,
        vwap: 267.957163,
        lasttradedatetime: "2022-07-07T16:00:00-04:00",
        lastquotedatetime: "2022-07-07T17:11:37-04:00",
        totalvalue: 5584610420,
        lastmarketidentificationcode: "NSD",
        bidmarketidentificationcode: "ARCA",
        askmarketidentificationcode: "ARCA",
        close: 268.4,
        imbalance: { imbalancetype: "None", imbalancesize: 0 },
      },
      fundamental: {
        sharesoutstanding: 7479033135,
        shareclasslevelsharesoutstanding: 7479033135,
        totalsharesoutstanding: 7479033135,
        marketcap: 2007372493434,
        eps: 9.57999992,
        peratio: 27.8,
        pbratio: 12.323,
        beta: 0.9330639839172363,
        week52high: { date: "2021-11-22", content: 349.67 },
        week52low: { date: "2022-06-14", content: 241.51 },
        week52highvwap: { date: "2021-11-22", content: 343.787009 },
        week52lowvwap: { date: "2022-06-14", content: 243.839082 },
        week52highvol: { date: "2022-01-26", content: 90428853 },
        week52lowvol: { date: "2021-08-05", content: 13873604 },
        week52avgvol: 29703520,
        dividend: {
          date: "2022-08-17",
          amount: 2.48,
          yield: 0.932,
          latestamount: { currency: "USD", content: 0.62 },
          frequency: "Q",
          paydate: "2022-09-08",
        },
        sharesescrow: 0,
        avgvol30days: 29051569,
        float: 7475126855,
      },
      classification: { sector: "Technology", industry: "Software" },
      symbolstring: "MSFT",
      datatype: "equity",
      entitlement: "RT",
      datetime: "2022-07-07T17:11:42-04:00",
    };
  }

  goToProfileScreen = (userName: string) => {
    let info: any = storageService.getUser();
    router.push({
      name: RouteEnum.profile,
      params: { username: info.userName },
    });
  };

  allRelations = [
    { name: "Friend", value: "Friend", icon: "friend" },
    { name: "Online Friend", value: "Online Friend", icon: "onlinefriend" },
    { name: "Colleague", value: "Colleague", icon: "colleague" },
    { name: "Relative", value: "Relative", icon: "relative" },
  ];

  liveUpdateDuration = 20000;

  generateSeriesForChart(series: any[]) {
    return series;
  }

  chartColor = {
    red: "#ef403c",
    green: "#00b746",
  };

  minDate = new Date().toISOString().split("T")[0];

  validateNumberInput(e: any) {
    e = e || window.event;
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  }

  isMobileView() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      if (window.screen.width < 768) {
        return true;
      }
      return false;
    }
  }

  alignPostByResponsiveness = (
    elemId: string,
    configuration: ConfigurationModel
  ) => {
    var feedList = document.getElementById(elemId);
    if (feedList) {
      this.setWidth(feedList.offsetWidth, configuration);
    }
  };

  setWidth = (screenWidth: number, configuration: ConfigurationModel) => {
    var totalCount = configuration.activitiesCount;
    var postTotalMargin = this.margin * (totalCount - 1);
    var oneColWidth = (screenWidth - postTotalMargin) / totalCount;
    // if (totalCount  1)
    // {
    //   oneColWidth += this.margin;
    // }
    if (oneColWidth > 700) {
      oneColWidth = 700;
    }

    configuration.maxWidth = oneColWidth + "px";
    if (oneColWidth < this.widthOfCol) {
      totalCount = totalCount - 1;
      configuration.activitiesCount = totalCount;
      configuration.widgetsCount = 0;
      this.setWidth(screenWidth, configuration);
    }
  };

  showDeleteConfirmationPopup = (title: string) => {
    return new Promise(async (resolve, reject) => {
      const modal = await openModal(AlertDeleteComponent, {
        title: title,
      });
      modal.on("close", (val: boolean) => {
        resolve(val);
      });
    });
  };
  convertToTitleCase(val: string) {
    return val
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(" ");
  }
  goToCryptoScreen = (stockId: string) => {
    router.push({
      name: "crypto",
      params: { stock: stockId },
    });
  };

  getUserFromProfile = (
    profile: ConnectionModel,
    isParsed: boolean = false
  ) => {
    var user: any = new User();
    var profileUser = userProfileUser().profileUser;
    var isOtherProfile: boolean = profileUser.value._id === profile.userId;
    user.fullName = isOtherProfile ? profile.connectionName : profile.userName;
    user.userName = isOtherProfile
      ? profile.connectionUsername
      : profile.userName;
    user.country = isOtherProfile
      ? profile.connectionCountry
      : profile.userCountry;
    user.gender = isOtherProfile
      ? profile.connectionGender
      : profile.userGender;
    user._id = isOtherProfile ? profile.connectionId : profile.userId;
    var media = this.generateImages(profile, isParsed);
    user.profilePicture = media.profilePicture;
    user.coverPhoto = media.coverPicture;
    return user;
  };

  generateImages(profile: ConnectionModel, isParsed: boolean = false) {
    var profileUser = storageService.getUser();
    var profileImg = "";
    var coverImg = "";
    if (profileUser._id === profile.userId) {
      profileImg = isParsed
        ? this.getUserProfilePicture(
            profile.connectionId!,
            profile.connectionProfilePicture!,
            profile.connectionGender!
          )
        : profile.connectionProfilePicture!;
      coverImg = isParsed
        ? this.getUserProfilePicture(
            profile.connectionId!,
            profile.connectionCoverPhoto!,
            profile.connectionGender!,
            true
          )
        : profile.connectionCoverPhoto!;
    } else {
      profileImg = isParsed
        ? this.getUserProfilePicture(
            profile.userId!,
            profile.userProfilePicture!,
            profile.userGender!
          )
        : profile.userProfilePicture!;
      coverImg = isParsed
        ? this.getUserProfilePicture(
            profile.userId!,
            profile.userCoverPhoto!,
            profile.userGender!,
            true
          )
        : profile.userCoverPhoto!;
    }
    return {
      profilePicture: profileImg,
      coverPicture: coverImg,
    };
  }

  camelCaseToWords(text: string) {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  numberFormatter(options = null) {
    return new Intl.NumberFormat(
      "en-US",
      options || {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
        // roundingIncrement: 5,
        // notation: "compact",
        unitDisplay: "short",
      }
    );
  }

  isValidDate(d: Date) {
    return d instanceof Date && !isNaN(d as any);
  }

  dateFormatter(
    locale = "en-GB",
    date: any,
    options: Intl.DateTimeFormatOptions = {}
  ) {
    const dateObj = new Date(date); // Ensure it's a Date object
    if (this.isValidDate(dateObj)) {
      return new Intl.DateTimeFormat(locale, options).format(dateObj);
    }
    return null;
  }

  commaSeperatedValue(value: any) {
    if (value) {
      return value.toLocaleString() ? value.toLocaleString() : 0;
    }
  }

  calculateSizeOfLayoutColumn = (size: string, parentWidget: any): string => {
    const clientWidth = (parentWidget!["clientWidth"] - 7) as number;
    const sizes = ["small", "medium", "large"];
    if (size) {
      size = size.toLowerCase();
    } else {
      size = sizes[0];
    }
    if (size == sizes[2] && clientWidth < 1200) {
      return this.calculateSizeOfLayoutColumn(sizes[1], parentWidget);
    } else if (size == sizes[1] && clientWidth < 800) {
      return sizes[0];
    }
    return size;
  };

  calculateWidthOfLayoutColumn = (
    size: string,
    parentWidget: any,
    columnCount: number
  ): number => {
    const clientWidth = (parentWidget!["clientWidth"] - 7) as number;
    const sizes = ["small", "medium", "large"];
    if (size) {
      size = size.toLowerCase();
    } else {
      size = sizes[0];
    }
    if (size == sizes[2] && clientWidth < 1200) {
      return this.calculateWidthOfLayoutColumn(
        sizes[1],
        parentWidget,
        columnCount
      );
    } else if (size == sizes[1] && clientWidth < 800) {
      return this.calculateWidthOfLayoutColumn(
        sizes[0],
        parentWidget,
        columnCount
      );
    } else {
      var totalColumns = columnCount;
      if (totalColumns == 3 && clientWidth < 1200) {
        totalColumns -= 1;
      } else if (totalColumns == 2 && clientWidth < 800) {
        totalColumns -= 1;
      }
      var wd = clientWidth / totalColumns;
      var widthPerColumn = this.widthOfCol;
      var finalWidth = this.widthOfCol;
      if (size == sizes[2]) {
        finalWidth = widthPerColumn * 3 + this.margin * 2;
      } else if (size == sizes[1]) {
        finalWidth = widthPerColumn * 2 + this.margin;
      } else {
        finalWidth = widthPerColumn;
      }
      return finalWidth;
    }
  };

  updateUserAfterConfiguration = async (user: any) => {
    try {
      const response = await httpService.updateUserAfterConfiguration(
        user.configuration
      );
      if (response.isSuccess) {
        storageService.setUser(response.data);
        return true;
      }
      return;
    } catch (e) {
      return;
    }
  };
}

export default new UtilService();
