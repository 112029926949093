import axios from "axios";
import axiosRetry from "axios-retry";

const baseURL = "/cache-service";
const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosRetry(instance, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    return retryCount * 2000; // time interval between retries
  },
});

export default instance;
