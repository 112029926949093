import { StockMarkets } from "./stock/stocks.model";

export class NewsSearchDTO
{
    feedId: NewsFeedIds;
    title: NewsSourceTitles;
    logoUrl: NewsSourceLogos;
    color: string | undefined;
    widgetType: string | undefined;

    constructor(feedId: NewsFeedIds, title: NewsSourceTitles, logoUrl: NewsSourceLogos)
    {
        this.feedId = feedId;
        this.title = title;
        this.logoUrl = logoUrl;
        if (feedId == NewsFeedIds.yahoo)
        {
            this.widgetType = StockMarkets.stocks;
            this.color = "#6600ff";
        } else if (feedId == NewsFeedIds.marketWatch)
        {
            this.color = "#ecd52d";
        } else if (feedId == NewsFeedIds.cryptoDaily)
        {
            this.widgetType = StockMarkets.crypto;
            this.color = "#FF851B";
        } else if (feedId == NewsFeedIds.cointelegraph)
        {
            this.color = "#ecd52d";
        } else
        {
            this.color = "#ecd52d";
        }
    }
}

export enum NewsSourceLogos
{
    cointelegraph = "https://firebasestorage.googleapis.com/v0/b/internaldev-361116.appspot.com/o/images%2Fcointelegraph-logo.jpeg?alt=media&token=887c58a7-6d85-49a3-aa72-02b632d3bbdd",
    cryptoDaily = "https://firebasestorage.googleapis.com/v0/b/internaldev-361116.appspot.com/o/images%2Fcryptodaily-logo.jpg?alt=media&token=95767f06-a03b-4784-b0a2-07fc12b8b063",
    marketWatch = "https://firebasestorage.googleapis.com/v0/b/internaldev-361116.appspot.com/o/images%2Fmarketwatch.png?alt=media&token=cd384ffa-ac28-4640-958e-3d82c730e5b9",
    yahoo = "https://firebasestorage.googleapis.com/v0/b/internaldev-361116.appspot.com/o/images%2Fyahoo.png?alt=media&token=a014c078-3902-4cf9-b869-06e04a555614",
}

export enum NewsSourceTitles
{
    cointelegraph = "Coin Telegraph",
    cryptoDaily = "Crypto Daily",
    marketWatch = "Market Watch",
    yahoo = "Yahoo Finance",
}

export enum NewsFeedIds
{
    cointelegraph = "COINTELEGRAPH",
    cryptoDaily = "CRYPTODAILY",
    marketWatch = "MARKETWATCH",
    yahoo = "YAHOO",
}