import { Section } from "@/composables/section/models/section.model";
import SocialLinks from "@/helpers/models/sociallinks.model";
import { UserProfileConfiguration } from "../../../../helpers/models/settings.model";
export class ProfileEntity {
    _id: string | undefined;
    fullName: string | undefined;
    userName: string | undefined;
    profilePicture: number | undefined;
    coverPhoto: number | undefined;
    privacy: ProfilePrivacy | undefined;
    sections: Section[] | undefined;
    isFollowing: boolean | undefined;
    isFriend: boolean | undefined;
    isRequestSent: boolean | undefined;
    isRequestReceived: boolean | undefined;
    requestSentData: RequestData | undefined;
    requestReceiveData: RequestData | undefined;
    socialLinks: SocialLinks[] | undefined;
    badges: string[] | undefined;
    bio: string | undefined;
    isProfileCompleted: boolean | undefined;
    followersCount: number | undefined;
    outreach: number | undefined;
    followingCount: number | undefined;
    friendCount: number | undefined;
    friendsCount: number | undefined;
    stats:any;
    viewsCount: number | undefined;
    profileConfiguration:  UserProfileConfiguration = new UserProfileConfiguration() ;
    createdOn: number | undefined ;
    userId: string | undefined;
    
}

export class RequestData {
    id: string | undefined;
    value: boolean | undefined;
}


export enum ProfilePrivacy {
    public = "public",
    private = "private",
}