<template>
  <div class="app-modal AddAndEditImages onAddPopup addNoteModal">
    <div class="app-modal--head">
      <h6>Add Notes</h6>
    </div>
    <div class="app-modal--body">
      <el-scrollbar class="bodyScrollDv">
        <div class="textAreaInfoDv">
          <div class="gridInfo">
            <div class="iteminfo">
              <h3>Comments</h3>
              <div class="searchinfoDv placeholderinfodv">
                <div class="scrollable-container">
                  <el-input
                    rows="5"
                    class="newInputDv"
                    type="textarea"
                    min="4"
                    minlength="4"
                    maxlength="100"
                    max="100"
                    v-model="notes"
                    placeholder="Enter Points"
                  />
                </div>
                <p v-if="!notes">Please Enter Comment</p>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="app-modal--footer">
      <div class="app-modal--footer-buttons">
        <button
          class="btn btn--outline"
          @click="onCancel()"
          @touchstart="onCancel()"
        >
          Cancel
        </button>
        <button
          :class="{ disabled: notes == '' }"
          class="btn btn--primary"
          @click="onSave()"
          @touchstart="onSave()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import storageService from "@/services/storage.service";
import utilService from "@/services/util.service";
import { popModal } from "jenesius-vue-modal";
import { defineComponent, ref } from "vue";
import { TYPE } from "vue-toastification";
import { defineProps } from "vue";

const propss = defineProps<{
  data: string;
}>();

export default defineComponent({
  emits: ["onSave"],
  props: {
    data: {
      type: String,
    },
  },
  setup(props, context) {
    const notes = ref(props?.data || "");
    const onCancel = () => popModal();
    const onSave = () => {
      if (notes.value) {
        context.emit("onSave", notes.value);
        popModal();
      } else {
        utilService.showToast("Please! Enter notes to continnue", TYPE.WARNING);
      }
    };

    return {
      onCancel,
      notes,
      onSave,
    };
  },
});
</script>
<style lang="scss" scoped>
.onAddPopup {
  height: 330px;
  &.addNoteModal {
    margin-left: calc(var(--closedSidebarWidth) + 45px);
    background: var(--base-dark);
    box-shadow: 0px -6px 20px 0px var(--color-darg-purple3);
    .app-modal--footer {
      box-shadow: none;
      margin-top: -var(--app-modal-inner-gap);
      padding: 0 var(--app-modal-body-gap) var(--app-modal-inner-gap);
    }
  }
}
.textAreaInfoDv {
  .gridInfo {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-template-columns: 1fr;
    .iteminfo {
      h3 {
        color: var(--base-text);
        font-size: var(--font-sm);
        margin: 0px 0px 10px;
        font-weight: 400;
        letter-spacing: 0px;
        padding: 0;
        line-height: 1;
      }
    }
  }
}

.placeholderinfodv {
  position: relative;
  p {
    font-size: var(--font-2xs);
    line-height: 1;
    letter-spacing: 0px;
    margin: 0px;
    padding: 0px;
    z-index: -1;
    cursor: pointer;
    position: absolute;
    top: 15px;
    left: 15px;
    color: grey;
  }
}
</style>
