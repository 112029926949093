import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "app-modal modal modal-fullscreen" }
const _hoisted_2 = { class: "app-modal--head flexRow" }
const _hoisted_3 = { class: "app-modal--body" }
const _hoisted_4 = { class: "modal_list_container watchlist watchlistForm playbookfromDv" }
const _hoisted_5 = { class: "gridinfoexistingplaybookDv" }
const _hoisted_6 = { class: "editmodePlaybookDv" }
const _hoisted_7 = { class: "watchlist-row playbookRow" }
const _hoisted_8 = { class: "PlaybookFlowCardDvChanging" }
const _hoisted_9 = { class: "LongShortDv" }
const _hoisted_10 = { class: "col firstRow" }
const _hoisted_11 = { class: "searchinfoDv newTextFeildDv" }
const _hoisted_12 = { class: "newInputInfoDv" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "searchinfoDv newTextFeildDv" }
const _hoisted_15 = { class: "newInputInfoDv" }
const _hoisted_16 = { class: "col firstRow" }
const _hoisted_17 = { class: "searchinfoDv newTextFeildDv" }
const _hoisted_18 = { class: "newInputInfoDv" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "searchinfoDv newTextFeildDv" }
const _hoisted_21 = { class: "newInputInfoDv" }
const _hoisted_22 = { class: "CustomCheckboxWrapper deletePlaybook" }
const _hoisted_23 = { class: "checkbox_wrapper widget" }
const _hoisted_24 = ["id", "value", "checked"]
const _hoisted_25 = { class: "watchlist-row watchlist-row-buttons" }
const _hoisted_26 = { class: "app-modal--footer" }
const _hoisted_27 = {
  key: 0,
  class: "error_txt"
}
const _hoisted_28 = { class: "playbookViewCardGridDv" }
const _hoisted_29 = { class: "app-modal--footer-buttons" }
const _hoisted_30 = {
  key: 0,
  class: "btn btn--primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconComponent = _resolveComponent("IconComponent")!
  const _component_PlayBookItem = _resolveComponent("PlayBookItem")!
  const _component_StockSelectComponent = _resolveComponent("StockSelectComponent")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_SelectionPreview = _resolveComponent("SelectionPreview")!
  const _component_ViewPlayBookCardItem = _resolveComponent("ViewPlayBookCardItem")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IconComponent, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dismiss())),
        class: "back-btn back-btn-container",
        icon: "icon-chevron-left.svg"
      }),
      _createElementVNode("h6", null, _toDisplayString(_ctx.existingPlaybook ? "Edit" : "Add New") + " Playbook", 1),
      _cache[35] || (_cache[35] = _createElementVNode("div", { class: "back-btn-container" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_scrollbar, {
        class: "verticalScroller newScrollbar native",
        native: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_PlayBookItem, {
                  fromPopup: true,
                  trade: _ctx.previewTrade,
                  editMode: true,
                  playbookTitle: _ctx.playbookData.title,
                  playbookDate: _ctx.playbookData.date,
                  disableRouting: true,
                  class: "c-pointer",
                  showRemove: false
                }, null, 8, ["trade", "playbookTitle", "playbookDate"]),
                (_ctx.existingPlaybook)
                  ? (_openBlock(), _createBlock(_component_IconComponent, {
                      key: 0,
                      class: "iconDv",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deletePlaybook())),
                      onTouchstart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deletePlaybook())),
                      id: "minusWining",
                      icon: "minus.svg"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(), _createBlock(_component_StockSelectComponent, {
                  fromWatchlist: true,
                  fromPlaybook: true,
                  key: _ctx.selectedStock,
                  stock: _ctx.selectedStock,
                  onOnStockUpdate: _cache[3] || (_cache[3] = ($event) => _ctx.onStockChange($event))
                }, null, 8, ["stock"])),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("h2", {
                      class: _normalizeClass(["long", {
                      activeNowLong: _ctx.playbookForm.traderType === 'long',
                    }]),
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleChange('long')))
                    }, " Long ", 2),
                    _createElementVNode("h2", {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleChange('short'))),
                      class: _normalizeClass(["short", {
                      activeNowShort: _ctx.playbookForm.traderType === 'short',
                    }])
                    }, " Short ", 2)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["watchlist-row inline-fields quad-col", { disabledSelectedStock: !_ctx.selectedStock }])
            }, [
              _createElementVNode("div", _hoisted_10, [
                _cache[36] || (_cache[36] = _createElementVNode("h3", null, "Entry", -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_IconComponent, {
                    class: "iconinfoDv",
                    icon: "icon-playbook-stockactualprice.svg"
                  }),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_IconComponent, {
                      class: "iconinfoDv",
                      icon: "icon-playbook-add.svg",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handlePrice('entry', 'plus')))
                    }),
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_input, {
                        class: "newInputDv",
                        placeholder: "Enter Price",
                        disabled: !_ctx.selectedStock,
                        max: "9999999",
                        type: "number",
                        onKeypress: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$filters.validateNumberInput($event, 9999999))),
                        modelValue: _ctx.playbookForm.entry,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.playbookForm.entry) = $event))
                      }, null, 8, ["disabled", "modelValue"])
                    ]),
                    _createVNode(_component_IconComponent, {
                      class: "iconinfoDv",
                      icon: "icon-playbook-minus.svg",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handlePrice('entry', 'minus')))
                    })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[37] || (_cache[37] = _createElementVNode("h3", null, "Stop Loss", -1)),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_IconComponent, {
                    class: "iconinfoDv",
                    icon: "icon-playbook-stockstopprice.svg"
                  }),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_IconComponent, {
                      class: "iconinfoDv",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handlePrice('stoploss', 'plus'))),
                      icon: "icon-playbook-add.svg"
                    }),
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_input, {
                        class: "newInputDv",
                        placeholder: "Enter Price",
                        disabled: !_ctx.selectedStock,
                        max: "9999999",
                        type: "number",
                        onKeypress: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$filters.validateNumberInput($event, 9999999))),
                        modelValue: _ctx.playbookForm.stopLoss,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.playbookForm.stopLoss) = $event))
                      }, null, 8, ["disabled", "modelValue"])
                    ]),
                    _createVNode(_component_IconComponent, {
                      class: "iconinfoDv",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.handlePrice('stoploss', 'minus'))),
                      icon: "icon-playbook-minus.svg"
                    })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[38] || (_cache[38] = _createElementVNode("h3", null, "Target 1", -1)),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_IconComponent, {
                    class: "iconinfoDv",
                    icon: "icon-playbook-stocktargetoneprice.svg"
                  }),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_IconComponent, {
                      class: "iconinfoDv",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.handlePrice('targetOne', 'plus'))),
                      icon: "icon-playbook-add.svg"
                    }),
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_input, {
                        class: "newInputDv",
                        placeholder: "Enter Price",
                        disabled: !_ctx.selectedStock,
                        max: "9999999",
                        onKeyup: _cache[15] || (_cache[15] = ($event: any) => (_ctx.updatePreviewCard())),
                        type: "number",
                        onKeypress: _cache[16] || (_cache[16] = ($event: any) => (_ctx.$filters.validateNumberInput($event, 9999999))),
                        modelValue: _ctx.playbookForm.targetOne,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.playbookForm.targetOne) = $event))
                      }, null, 8, ["disabled", "modelValue"])
                    ]),
                    _createVNode(_component_IconComponent, {
                      class: "iconinfoDv",
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.handlePrice('targetOne', 'minus'))),
                      icon: "icon-playbook-minus.svg"
                    })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[39] || (_cache[39] = _createElementVNode("h3", null, "Target 2", -1)),
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_IconComponent, {
                    class: "iconinfoDv",
                    icon: "icon-playbook-stocktargettwoprice.svg"
                  }),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_IconComponent, {
                      class: "iconinfoDv",
                      onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.handlePrice('targetTwo', 'plus'))),
                      icon: "icon-playbook-add.svg"
                    }),
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_input, {
                        class: "newInputDv",
                        disabled: !_ctx.selectedStock,
                        placeholder: "Enter Price",
                        max: "9999999",
                        type: "number",
                        onKeypress: _cache[20] || (_cache[20] = ($event: any) => (_ctx.$filters.validateNumberInput($event, 9999999))),
                        modelValue: _ctx.playbookForm.targetTwo,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.playbookForm.targetTwo) = $event))
                      }, null, 8, ["disabled", "modelValue"])
                    ]),
                    _createVNode(_component_IconComponent, {
                      class: "iconinfoDv",
                      onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.handlePrice('targetTwo', 'minus'))),
                      icon: "icon-playbook-minus.svg"
                    })
                  ])
                ])
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["dual-space-between", { disabledSelectedStock: !_ctx.selectedStock }])
            }, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    class: "custom-checkbox",
                    id: `info${_ctx.playbookData.strategy}`,
                    value: _ctx.playbookData.strategy,
                    checked: _ctx.playbookData.strategy,
                    onChange: _cache[23] || (_cache[23] = ($event: any) => (_ctx.changeStatus()))
                  }, null, 40, _hoisted_24)
                ]),
                _cache[40] || (_cache[40] = _createElementVNode("div", { class: "checkboxLableDv" }, "I am playing this strategy", -1))
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("button", {
                  class: _normalizeClass(["btn btn--primary secondary", { disabled: !_ctx.selectedStock }]),
                  onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.showNotesModal()))
                }, [
                  _createVNode(_component_IconComponent, {
                    class: "iconinfoDv",
                    icon: "icon-playbook-stocknotes.svg"
                  }),
                  _cache[41] || (_cache[41] = _createTextVNode("Notes "))
                ], 2),
                (_ctx.editableIndex == -1)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: _normalizeClass(["btn btn--primary", { disabled: !_ctx.selectedStock }]),
                      onClick: _cache[25] || (_cache[25] = _withModifiers(($event: any) => (_ctx.addStock()), ["stop"])),
                      onTouchstart: _cache[26] || (_cache[26] = ($event: any) => (_ctx.addStock()))
                    }, " Add Entry ", 34))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: _normalizeClass(["btn btn--primary", { disabled: !_ctx.selectedStock }]),
                      onClick: _cache[27] || (_cache[27] = _withModifiers(($event: any) => (_ctx.updateStock()), ["stop"])),
                      onTouchstart: _cache[28] || (_cache[28] = ($event: any) => (_ctx.updateStock()))
                    }, " Update Entry ", 34))
              ])
            ], 2)
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createVNode(_component_SelectionPreview, {
        count: _ctx.stockListing.length
      }, null, 8, ["count"]),
      (_ctx.stockListing.length == 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_27, "No Data Found"))
        : (_openBlock(), _createBlock(_component_el_scrollbar, {
            key: 1,
            class: "verticalScroller newScrollbar native playbookFooterScroll",
            native: true
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_28, [
                _createVNode(_component_draggable, {
                  "force-fallback": true,
                  onStart: _cache[29] || (_cache[29] = ($event: any) => (_ctx.handleDragEvent('start'))),
                  onEnd: _cache[30] || (_cache[30] = ($event: any) => (_ctx.handleDragEvent('stop'))),
                  modelValue: _ctx.stockListing,
                  "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.stockListing) = $event)),
                  "item-key": "index",
                  class: "watchlist-listing-container lastinfoDvWatchlistContainer"
                }, {
                  item: _withCtx(({ element, index }) => [
                    _createVNode(_component_ViewPlayBookCardItem, {
                      onOnRemove: ($event: any) => (_ctx.removeStock(index)),
                      class: _normalizeClass(["c-pointer", {
                    shortActive: element.traderType == 'short',
                    longActive: element.traderType == 'long',
                  }]),
                      showRemove: true,
                      onClick: _withModifiers(($event: any) => (_ctx.onEventUpdate(element, index)), ["stop"]),
                      stock: element
                    }, null, 8, ["onOnRemove", "onClick", "class", "stock"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _: 1
          })),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("button", {
          class: "btn btn--outline",
          onClick: _cache[32] || (_cache[32] = ($event: any) => (_ctx.dismiss()))
        }, "Cancel"),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("button", _hoisted_30, _cache[42] || (_cache[42] = [
              _createElementVNode("div", { class: "loader" }, null, -1)
            ])))
          : (_ctx.existingPlaybook)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn--primary",
                onClick: _cache[33] || (_cache[33] = ($event: any) => (_ctx.updatePlaybook()))
              }, " Update "))
            : (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "btn btn--primary",
                onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.addPlaybook()))
              }, " Add "))
      ])
    ])
  ]))
}