import utilService from "@/services/util.service";
import { Socket, io } from "socket.io-client";
import { ref, watchEffect } from "vue";

let socket: Socket;
const predicitionDataInfo = ref<any>({});
const predictionIds = ref<any>([]);

export const usePredictionSocket = () => {
  const connectToPredictionSocket = () => {
    if (!socket || !socket.connected) {
      socket = io(utilService.getLiveHostURL());
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (error) => {});
    }
  };

  var count = 1;
  const getPredictionsByID = () => {
    if (socket && socket.connected && predictionIds.value.length) {
      count++;
      socket.emit(
        "getPredictionsByID",
        JSON.stringify(predictionIds.value),
        (response: any) => {
          if (response) {
            response.forEach((data: any) => {
              predicitionDataInfo.value[data.predictionId] = data;
            });
          }
        }
      );
    }
  };

  const clearPredictionIds = () => {
    predictionIds.value = [];
    predicitionDataInfo.value = {};
  };

  const disconnectPredictionFromSocket = () => {
    if (socket) {
      socket.removeAllListeners();
      socket.close();
    }
  };

  watchEffect(() => {
    if (predictionIds.value.length) {
      getPredictionsByID();
    }
  });

  return {
    connectToPredictionSocket,
    getPredictionsByID,
    clearPredictionIds,
    disconnectPredictionFromSocket,
    predicitionDataInfo,
    predictionIds,
  };
};
