<template>
  <div class="stock-select">
    <div
      class="stock-select-container"
      :class="{
        watchlistStockSelectComponent: fromWatchlist,
      }"
    >
      <h6>Ticker</h6>
      <div
        class="stock-select-container-search"
        v-if="!selectedStock || (hideSelected && selectedStock)"
      >
        <el-autocomplete
          v-model="stockKeyword"
          :fetch-suggestions="onStockSearch"
          popper-class="stock-autocomplete"
          placeholder="Search Stock"
          @select="onStockSelect"
          @focus="isStockFieldFocused = true"
          @blur="isStockFieldFocused = false"
        >
          <template #default="{ item }">
            <div class="autocomplete-stock">
              <StockImageTag
                :stockId="item.stockId"
                :disableRouting="true"
                :imgUrl="item.logoUrl"
                :symbol="item.symbol"
                :market="item.type"
              />
              <h6>
                {{ item.symbol }}
                <span>{{ item.name }}</span>
              </h6>
            </div>
          </template>
        </el-autocomplete>
        <!-- <p
          :class="{ inputFocused: isStockFieldFocused || stockKeyword }"
          class="note"
        >
          ex AAPL, MSFT, TSLA
        </p> -->
      </div>
    </div>
    <template v-if="selectedStock && !hideSelected">
      <template v-if="fromPlaybook">
        <div class="stock-select-active fromplaybook">
          <StockItemWidget :disableRouting="true" :stockData="selectedStock" />
          <IconComponent
            class="iconDv"
            @click="removeSelectedStock()"
            @touchstart="removeSelectedStock()"
            icon="minus.svg"
          />
          <!-- <a @click="removeSelectedStock()">
            <IconComponent icon="icon-closing-remove.svg" />
            <p>Remove</p>
          </a> -->
        </div>
      </template>
      <template v-else>
        <div class="stock-select-active">
          <StockItemWidget :disableRouting="true" :stockData="selectedStock" />
          <a @click="removeSelectedStock()">
            <IconComponent icon="icon-closing-remove.svg" />
            <p>Remove</p>
          </a>
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { useStockSocket } from "@/composables/stock_socket.composable";
import { SocketStock } from "@/helpers/models/stock/socket_stock.model";
import utilService from "@/services/util.service";
import { defineComponent, onMounted, ref } from "vue";
import { TYPE } from "vue-toastification";
import StockItemWidget from "../FavouriteInvestment/StockItemWidget.vue";

export default defineComponent({
  components: {
    StockItemWidget,
  },
  props: {
    stock: SocketStock,
    fromPlaybook: Boolean,
    fromWatchlist: Boolean,
    hideSelected: Boolean,
  },
  emits: ["onStockUpdate"],
  setup(props, context) {
    const stockKeyword = ref<string>("");
    const selectedStock = ref<SocketStock | undefined>(undefined);
    const { searchStocks } = useStockSocket();
    const isStockFieldFocused = ref<boolean>(false);

    onMounted(() => {
      selectedStock.value = props.stock;
    });

    const onStockSearch = (queryString: string, cb: any) => {
      if (!stockKeyword.value || stockKeyword.value.length < 2) {
        cb([]);
        return;
      }
      searchStocks(stockKeyword.value)
        .then((res: any) => {
          cb(res.slice(0, 20));
        })
        .catch((e) => {
          utilService.showToast("Error Getting Stocks", TYPE.ERROR);
          cb([]);
        });
    };

    const onStockSelect = (item: SocketStock) => {
      if (item && item.stockId) {
        selectedStock.value = item;
      }
      emitStockUpdate();
      isStockFieldFocused.value = false;
    };

    const removeSelectedStock = () => {
      selectedStock.value = undefined;
      emitStockUpdate();
    };

    const emitStockUpdate = () => {
      context.emit("onStockUpdate", selectedStock.value);
    };

    return {
      isStockFieldFocused,
      stockKeyword,
      selectedStock,
      onStockSearch,
      onStockSelect,
      removeSelectedStock,
      emitStockUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "./stockselect";
</style>
