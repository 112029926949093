export enum SectionType {
  shareProfile = "shareProfile",
  topStats = "topStats",
  requests = "requests",
  traderType = "traderType",
  favouriteInvestment = "favouriteInvestment",
  winningTrades = "winningTrades",
  portfolio = "portfolio",
  recommendation = "recommendation",
  predictions = "predictions",
  profitAndLoss = "profitAndLoss",
  benchmarking = "benchmarking",
  predictionsOHLC = "predictionsOHLC",
  watchlists = "watchlists",
  favouriteInfluencer = "favouriteInfluencer",
  links = "links",
  points = "points",
  images = "images",
  playbook = "playbook",
  highlightStats = "highlightStats",
  highlightStatsRight = "highlightStatsRight",
  highlightStatsMiddle = "middle",
}

export class Section {
  _id?: string | undefined;
  data?: any;
  modifiedBy?: string | undefined;
  modifiedOn?: number | undefined;
  name: string | undefined;
  order?: number | undefined;
  type: SectionType | undefined;
  userId?: string | undefined;
  privacy?: string | undefined;
  status?: string | undefined;
  isEnabled?: boolean | undefined;
  description: string | undefined;
  iconName: string | undefined;

  public static sectionLists: Section[] = [
    {
      name: "Top Stats",
      type: SectionType.topStats,
      description: "Key metrics for informed trading decisions",
      iconName: "1",
    },
    // {
    //   name: "Requests",
    //   type: SectionType.traderType,
    //   description: "Connect with similar trading styles easily",
    //   iconName: "2",
    // },
    {
      name: "Trader Type",
      type: SectionType.traderType,
      description: "Connect with similar trading styles easily",
      iconName: "2",
    },
    {
      name: "Favorite Investments",
      type: SectionType.favouriteInvestment,
      description: "Track preferred assets effortlessly for better management",
      iconName: "3",
    },
    {
      name: "Best Trades",
      type: SectionType.winningTrades,
      description: "Showcase your most successful trading endeavors",
      iconName: "4",
    },
    {
      name: "Portfolio",
      type: SectionType.portfolio,
      description: "Monitor investment performance with ease here",
      iconName: "5",
    },
    {
      name: "Recommendation",
      type: SectionType.recommendation,
      description: "Receive tailored investment suggestions for improved decisions",
      iconName: "6",
    },
    {
      name: "Prediction",
      type: SectionType.predictions,
      description: "Predict market movements with precision and confidence",
      iconName: "6",
    },
    {
      name: "Profit and Loss",
      type: SectionType.profitAndLoss,
      description: "Track your trading performance effectively here",
      iconName: "4",
    },
    {
      name: "Watchlist",
      type: SectionType.watchlists,
      description: "Monitor specific assets seamlessly for informed decisions",
      iconName: "4",
    },
    // {
    //   name: "Benchmarking",
    //   type: SectionType.benchmarking,
    //   description: "Compare performance against industry standards easily",
    //   iconName: "4",
    // },
    // {
    //   name: "Predictions OHLC",
    //   type: SectionType.predictionsOHLC,
    //   description: "Access accurate price forecasts conveniently here",
    //   iconName: "4",
    // },
    {
      name: "Lists",
      type: SectionType.points,
      description: "Earn rewards and recognition through engagement",
      iconName: "4",
    },
    {
      name: "Links",
      type: SectionType.links,
      description: "Connect users to external content",
      iconName: "4",
    },
    {
      name: "Favorite Influencer",
      type: SectionType.favouriteInfluencer,
      description: "Showcase and follow top voices",
      iconName: "4",
    },
    {
      name: "Images",
      type: SectionType.images,
      description: "Visually enhance your content",
      iconName: "4",
    },
    {
      name: "Playbook",
      type: SectionType.playbook,
      description: "Guide users through a specific process",
      iconName: "4",
    },
    {
      name: "Highlight Stats Left",
      type: SectionType.highlightStats,
      description: "Key metrics for informed trading decisions",
      iconName: "4",
    },
    {
      name: "Highlight Stats Right",
      type: SectionType.highlightStatsRight,
      description: "Key metrics for informed trading decisions",
      iconName: "4",
    },
  ];
}
