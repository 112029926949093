import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-modal modal modal-fullscreen viewFullScreeninfoDv"
}
const _hoisted_2 = { class: "app-modal--head flexRow" }
const _hoisted_3 = {
  key: 1,
  class: "add-btn"
}
const _hoisted_4 = { class: "app-modal--body" }
const _hoisted_5 = { class: "modal_list_container newPlaybookViewGridDv" }
const _hoisted_6 = { class: "app-modal--footer" }
const _hoisted_7 = { class: "app-modal--footer-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconComponent = _resolveComponent("IconComponent")!
  const _component_ViewPlayBookCardItem = _resolveComponent("ViewPlayBookCardItem")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_ctx.playbookInfo && _ctx.ShowModalBit)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_IconComponent, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dismiss())),
            class: "back-btn back-btn-container",
            icon: "icon-chevron-left.svg"
          }),
          _createElementVNode("h6", null, _toDisplayString(_ctx.playbookInfo.title), 1),
          (_ctx.currentUserId == _ctx.userID)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "add-btn has-bg",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openPlayBookForm()))
              }, [
                _createVNode(_component_IconComponent, {
                  path: "/Profile_Tabs",
                  class: "addIcon",
                  icon: "Edit-Profile.svg"
                }),
                _cache[3] || (_cache[3] = _createTextVNode(" Edit "))
              ]))
            : (_openBlock(), _createElementBlock("a", _hoisted_3))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_scrollbar, {
            class: "verticalScroller newScrollbar native",
            native: true
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playbookInfo.stocks, (item) => {
                  return (_openBlock(), _createBlock(_component_ViewPlayBookCardItem, {
                    key: item,
                    class: _normalizeClass({
                shortActive: item.traderType == 'short',
                longActive: item.traderType == 'long',
              }),
                    stock: item
                  }, null, 8, ["class", "stock"]))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              class: "btn btn--outline",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dismiss()))
            }, "Close")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}