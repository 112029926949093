import httpService from "@/services/http.service";
import { ref } from "vue";
import { Section, SectionType } from "./models/section.model";
import { AddSectionDTO } from "./models/add-section.dto";
import { UpdateSectionDTO } from "./models/update-section.dto";
import storageService from "@/services/storage.service";
import User from "@/helpers/models/user.model";
import { useProfileScreenComposable } from "@/views/private/profile-screen/composables/profile.screen.composable";
const sections = ref<Section[]>([]);
export const useSectionComposable = () => {

  const isAddLoader = ref<boolean>(false);

  const mapSectionValue = async (profileSections: Section[],
    user: User | undefined = undefined
  ) => {
    return new Promise((resolve, reject) => {
      try {
        sections.value = profileSections;
        var keys = Object.keys(SectionType);
        for (let sect of sections.value) {
          var ind = keys.findIndex((x) => x == sect.type);
          if (ind != -1) {
            keys.splice(ind, 1);
          }
          if (sect.type == SectionType.favouriteInvestment) {
            if (sect.name == "Favourite Investment") {
              sect.name = "Favorite Investments";
            }
            if (!sect.data) {
              sect.data = {
                stocks:
                  user &&
                    user.favouriteStocks &&
                    user.favouriteStocks.length
                    ? user.favouriteStocks
                    : [],
              };
            }
          } else if (sect.type == SectionType.topStats) {
            if (!sect.data) {
              sect.data = {
                stats:
                  user && user.stats && user.stats.length ? user.stats : [],
              };
            }
          } else if (sect.type == SectionType.highlightStats) {
            if (!sect.data) {
              sect.data = {
                stats:
                  user && user.stats && user.stats.length ? user.stats : [],
              };
            }
          } else if (sect.type == SectionType.highlightStatsRight) {
            sect.name === 'Highlights Right';
            if (!sect.data) {
              sect.data = {
                stats:
                  user && user.stats && user.stats.length ? user.stats : [],
              };
            }
          } else if (sect.type == SectionType.traderType) {
            if (!sect.data) {
              sect.data = {
                traderTypes: [],
              };
            }
          } else if (sect.type == SectionType.winningTrades) {
            if (sect.name == "Winning Trades") {
              sect.name = "Best Trades";
            }
          } else if (sect.type == SectionType.portfolio) {
            if (sect.name == "Portfolio") {
              sect.name = "Favorite Platforms";
            }
          }
        }
      } catch (e) {
      }
      resolve(true);
    })
  }

  const getAllSections = (
    userId: string,
    user: User | undefined = undefined
  ) => {
    return new Promise((resolve, reject) => {
      sections.value = [];
      httpService
        .getAllSections(userId)
        .then(async (res) => {
          if (res.isSuccess) {
            await mapSectionValue(res.data, user);
          }
          resolve(true);
        })
        .catch((e) => {
          resolve(false);
        });
    });
  };

  const addSection = (section: Section, toAdd: boolean = false) => {
    return new Promise((resolve, reject) => {
      if (sections.value.findIndex((x) => x.type == section.type) == -1) {
        isAddLoader.value = true;
        var addSectionObj = new AddSectionDTO();
        addSectionObj.name = section.name;
        addSectionObj.type = section.type;
        httpService
          .addSection(addSectionObj)
          .then((res) => {
            if (res.isSuccess) {
              if (toAdd) {
                sections.value.push(res.data);
              }
            }
            isAddLoader.value = false;
            resolve(true);
          })
          .catch((e) => {
            isAddLoader.value = false;
            resolve(false);
          });
      } else {
        resolve(false);
      }
    });
  };

  const updateSection = (section: Section) => {
    return new Promise((resolve, reject) => {
      var updateObj: UpdateSectionDTO = {
        data: section.data,
        name: section.name,
      };
      httpService
        .updateSection(section._id!, updateObj)
        .then((res) => {
          if (res && res.isSuccess) {
            res.data.privacy = section.privacy;
            res.data.status = section.status;
            const { updateProfileSection } = useProfileScreenComposable();
            updateProfileSection(res.data);
            var ind = sections.value.findIndex((x) => x._id == section._id);
            if (ind != -1) {
              sections.value[ind] = res.data;
            }
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((e) => {
          resolve(false);
        });
    });
  };

  const getSectionRefByType = (
    type: SectionType,
    user: User | undefined = undefined
  ) => {
    var ind = sections.value.findIndex((x) => x.type == type);
    if (ind != -1) {
      if (!sections.value[ind].data) {
        if (type == SectionType.portfolio) {
          sections.value[ind].data = {
            portfolios: [],
          };
        } else if (type == SectionType.traderType) {
          sections.value[ind].data = {
            traderTypes: [],
          };
        } else if (type == SectionType.topStats) {
          sections.value[ind].data = {
            stats: [],
          };
        } else if (type == SectionType.highlightStats) {
          sections.value[ind].data = {
            stats: [],
          };
        } else if (type == SectionType.highlightStatsRight) {
          sections.value[ind].data = {
            stats: [],
          };
        } else if (type == SectionType.playbook) {
          sections.value[ind].data = {
            playbook: [],
          };
        } else if (
          type == SectionType.recommendation ||
          type == SectionType.winningTrades ||
          type == SectionType.profitAndLoss ||
          type == SectionType.predictions ||
          type == SectionType.watchlists ||
          type == SectionType.predictionsOHLC ||
          type == SectionType.links ||
          type == SectionType.favouriteInfluencer ||
          type == SectionType.points ||
          type == SectionType.images
        ) {
          sections.value[ind].data = {
            list: [],
          };
        } else if (type == SectionType.favouriteInvestment) {
          if (!sections.value[ind].data) {
            sections.value[ind].data = {
              stocks:
                user && user.favouriteStocks && user.favouriteStocks.length
                  ? user.favouriteStocks
                  : [],
            };
          }
        }
      }
      var currentUser = storageService.getUser();
      if (currentUser && currentUser._id) {
        var topStatSectionIndex = sections.value.findIndex(
          (x) => x.type == SectionType.topStats
        );
        if (
          topStatSectionIndex != -1 &&
          sections.value[topStatSectionIndex] &&
          sections.value[topStatSectionIndex].data &&
          sections.value[topStatSectionIndex].data.stats
        ) {
          var statsArr = sections.value[topStatSectionIndex].data.stats;
          for (let stat of statsArr) {
            if (stat.label == "Followers") {
              stat.value = currentUser.followersCount;
            } else if (stat.label == "Views") {
              stat.value = currentUser.viewsCount;
            }
          }
        }
      }
      return sections.value[ind];
    }
    return null;
  };

  const updateAllSection = () => {
    for (let i = 0; i < sections.value.length; i++) {
      sections.value[i].order = i;
    }
    try {
      httpService.updateSectionOrders(sections.value);
    } catch (e) { }
  };

  const mapSectionFromProfileSection = (section: Section) => {
    var ind = sections.value.findIndex(x => x.type == section.type);
    if (ind != -1) {
      sections.value[ind].data = section.data;
    }
  }

  const getInitialSectionData = (type: SectionType) => {
    if (type == SectionType.portfolio) {
      return {
        portfolios: [],
      };
    } else if (type == SectionType.traderType) {
      return {
        traderTypes: [],
      };
    } else if (type == SectionType.topStats || type == SectionType.highlightStats || type == SectionType.highlightStatsRight) {
      return {
        stats: [],
      };
    } else if (
      type == SectionType.recommendation ||
      type == SectionType.winningTrades ||
      type == SectionType.profitAndLoss ||
      type == SectionType.predictions ||
      type == SectionType.predictionsOHLC ||
      type == SectionType.points ||
      type == SectionType.links ||
      type == SectionType.images ||
      type == SectionType.favouriteInfluencer ||
      type == SectionType.watchlists
    ) {
      return {
        list: [],
      };
    } else if (type == SectionType.favouriteInvestment) {
      return {
        stocks: [],
      };
    } else if (type == SectionType.playbook) {
      return {
        playbook: [],
      };
    }
  }

  const getSectionDataFieldName = (type: SectionType) => {
    try {
      if (type == SectionType.portfolio) {
        return 'portfolios';
      } else if (type == SectionType.traderType) {
        return 'traderTypes';
      } else if (type == SectionType.topStats || type == SectionType.highlightStats || type == SectionType.highlightStatsRight) {
        return 'stats';
      } else if (type == SectionType.watchlists){
        return 'list';
      } else if (
        type == SectionType.recommendation ||
        type == SectionType.winningTrades ||
        type == SectionType.profitAndLoss ||
        type == SectionType.predictions ||
        type == SectionType.predictionsOHLC ||
        type == SectionType.points ||
        type == SectionType.links ||
        type == SectionType.images ||
        type == SectionType.favouriteInfluencer
      ) {
        return 'list';
      } else if (type == SectionType.favouriteInvestment) {
        return 'stocks';
      } else if (type == SectionType.playbook) {
        return 'playbook';
      }
    } catch (e) {
    }
    return null;
  }

  return {
    getSectionDataFieldName,
    getInitialSectionData,
    getSectionRefByType,
    updateAllSection,
    addSection,
    updateSection,
    mapSectionFromProfileSection,
    mapSectionValue,
    isAddLoader,
    sections,
    getAllSections,
  };
};
