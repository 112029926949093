import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../../assets/images/dummy-cover.png'


const _hoisted_1 = {
  key: 0,
  class: "qrCodeSection"
}
const _hoisted_2 = { class: "qrCodeHead" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "qrCodeBody" }
const _hoisted_7 = { class: "body" }
const _hoisted_8 = { class: "ProfileDetailDv" }
const _hoisted_9 = { class: "firstDv" }
const _hoisted_10 = { class: "middleDv" }
const _hoisted_11 = { class: "profileiconinfoDv" }
const _hoisted_12 = { class: "firstDv justify-content-end" }
const _hoisted_13 = { class: "ProfiletexinfoDv" }
const _hoisted_14 = { class: "spacingInfoDv" }
const _hoisted_15 = { class: "scanQrCodeDv" }
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 0,
  class: "itemInfo"
}
const _hoisted_18 = {
  key: 1,
  class: "itemInfo"
}
const _hoisted_19 = { class: "foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopStatItemComponent = _resolveComponent("TopStatItemComponent")!
  const _component_QRAvatarComponent = _resolveComponent("QRAvatarComponent")!
  const _component_InvestmentBarComponent = _resolveComponent("InvestmentBarComponent")!
  const _component_IconComponent = _resolveComponent("IconComponent")!
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.images.coverPhoto && _ctx.images.coverPhoto !== '')
            ? (_openBlock(), _createElementBlock("figure", _hoisted_3, [
                _createElementVNode("img", {
                  src: _ctx.images.coverPhoto,
                  onError: _cache[0] || (_cache[0] = (event) => (event.srcElement.src = _ctx.imageError()))
                }, null, 40, _hoisted_4)
              ]))
            : _createCommentVNode("", true),
          (
          !_ctx.images.coverPhoto || (_ctx.images.coverPhoto && _ctx.images.coverPhoto == '')
        )
            ? (_openBlock(), _createElementBlock("figure", _hoisted_5, _cache[6] || (_cache[6] = [
                _createElementVNode("img", {
                  crossorigin: "anonymous",
                  src: _imports_0
                }, null, -1)
              ])))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                (_ctx.allLeftStats && _ctx.allLeftStats.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.allLeftStats.slice(0, 1), (item) => {
                      return (_openBlock(), _createBlock(_component_TopStatItemComponent, {
                        class: "showShort",
                        key: item,
                        stat: item
                      }, null, 8, ["stat"]))
                    }), 128))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  (_ctx.images.profilePicture && _ctx.images.profilePicture !== '')
                    ? (_openBlock(), _createBlock(_component_QRAvatarComponent, {
                        key: _ctx.images.profilePicture,
                        profileImage: _ctx.images.profilePicture,
                        border: true,
                        badges: false,
                        class: "large",
                        showQRCode: false
                      }, null, 8, ["profileImage"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allRightStats.slice(0, 1), (item) => {
                  return (_openBlock(), _createBlock(_component_TopStatItemComponent, {
                    key: item,
                    class: "showShort",
                    stat: item
                  }, null, 8, ["stat"]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.user.fullName), 1),
              _createElementVNode("p", null, "@" + _toDisplayString(_ctx.user.userName), 1)
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_ctx.favouriteInvestmentStocks && _ctx.favouriteInvestmentStocks.length)
                ? (_openBlock(), _createBlock(_component_InvestmentBarComponent, {
                    key: 0,
                    fromqrcodescreen: true,
                    stocks: _ctx.favouriteInvestmentStocks
                  }, null, 8, ["stocks"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_15, [
              (_ctx.qrCodeImage && _ctx.qrCodeImage != '')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("img", {
                      crossorigin: "anonymous",
                      src: _ctx.qrCodeImage
                    }, null, 8, _hoisted_16),
                    _createVNode(_component_IconComponent, { icon: "icon-new-log.svg" })
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "profileLink" }, [
              _createElementVNode("a", null, "Profile Link")
            ], -1)),
            (_ctx.user)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _cache[7] || (_cache[7] = _createElementVNode("p", null, "Personal info as VCard", -1)),
                  _createVNode(_component_el_switch, {
                    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getQrCode())),
                    modelValue: _ctx.generateVcard,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.generateVcard) = $event))
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.user)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _cache[8] || (_cache[8] = _createElementVNode("p", null, "Pre approved Friend Request", -1)),
                  _createVNode(_component_el_switch, {
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getQrCode())),
                    modelValue: _ctx.sendFollowRequest,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sendFollowRequest) = $event))
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_19, [
            _cache[10] || (_cache[10] = _createElementVNode("p", null, " Share your QR with your friends, so they can add you as a contact. ", -1)),
            _createElementVNode("a", {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resetQRCode()))
            }, "Reset QR Code")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}