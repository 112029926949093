export class NotificationModel {
    _id: string | undefined;
    notificationId: string | undefined;
    createdOn: string | undefined;
    modifiedOn: string | undefined;
    userIds: string[] | undefined;
    notificationType: string | undefined;
    data: any;
    isViewed: boolean = false;
    isDeleted: boolean = false;
}