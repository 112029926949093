export enum AppEnvironments {
  internal = "internal",
  dev = "dev",
  stage = "stage",
  production = "production",
}


export class Environment {

  static get currentEnvironment(): AppEnvironments {
    if (
      window.location.href.toLowerCase().includes('profiles.traderverse.io') ||
      window.location.href.toLowerCase().includes('profiletest-uat') ||
      window.location.href.toLowerCase().includes('app.traderverse.io')
    ) {
      return AppEnvironments.production;
    } else if (window.location.href.toLowerCase().includes('internal-profile') || window.location.href.toLowerCase().includes('social-internal') || window.location.host.toLowerCase().includes('74.208.95.190')) {
      return AppEnvironments.internal;
    } else if (window.location.href.toLowerCase().includes('profiles-stage') || window.location.href.toLowerCase().includes('social-stage') || window.location.host.toLowerCase().includes('74.208.115.53')) {
      return AppEnvironments.stage;
    }
    return AppEnvironments.dev;
  }

  static get domain(): string {
    if (window.location.href.toLowerCase().includes('profiles.traderverse.io')) {
      return "profiles.traderverse.io";
    } else if (window.location.href.toLowerCase().includes('app.traderverse.io')) {
      return "app.traderverse.io";
    }else if (window.location.href.toLowerCase().includes('profiles.traderverse.io')) {
      return "profiletest-uat.traderverse.io";
    }else if (window.location.href.toLowerCase().includes('internal-profile') || window.location.href.toLowerCase().includes('social-internal') || window.location.host.toLowerCase().includes('74.208.95.190')) {
      return "internal-profile.traderverse.io";
    } else if (window.location.href.toLowerCase().includes('profiles-stage') || window.location.href.toLowerCase().includes('social-stage') || window.location.host.toLowerCase().includes('74.208.115.53')) {
      return "profiles-stage.traderverse.io";
    }
    return "profiles-develop.traderverse.io";
  }
}