import utilService from "@/services/util.service";
import { StockMarkets } from "./stocks.model";

class ChartHeader {
  symbol: string | any | undefined;
  companyName: string | undefined;
  type: string | undefined;
  logoUrl: string | undefined;
  change: number | undefined;
  changePercent: number | undefined;
  last: number | undefined;
  _id: string | undefined;
  stockId: string | undefined;

  public static copyFromStockFirebase(source: any): ChartHeader {
    var chartHeader = new ChartHeader();
    chartHeader._id = source._id ?? "";
    chartHeader.symbol = source.stockId ?? "";
    chartHeader.companyName = source.companyName ?? "";
    chartHeader.type = source.type ?? "";
    if (typeof source.change == 'number') {
      chartHeader.change = source.change ?? 0;
    } else {
      chartHeader.change = 0;
    }
    chartHeader.changePercent = source.changePercent ?? 0;
    if (typeof source.last == 'number') {
      chartHeader.last = source.last ?? 0;
    } else {
      chartHeader.last = 0;
    }
    chartHeader.logoUrl = utilService.getStockCDNImage(source.logoUrl, source._id ?? chartHeader.symbol, chartHeader.type ?? StockMarkets.stocks);
    return chartHeader;
  }
}

export default ChartHeader;
