import filters from "./filters";

export const invalidValueFilter = [
    "n/a",
    "na",
    "NA",
    "N/A",
];

export const isValueValid = (value: any) => {
    return !invalidValueFilter.includes(value);
}

export const recaptchaKey = "6LfutcckAAAAAMEXp3meRytLdgNtReBnGnyk-4kY";