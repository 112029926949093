import { SocketStock } from "@/helpers/models/stock/socket_stock.model";
import { onMounted, ref } from "vue";
import { useStockSocket } from "./stock_socket.composable";

const { getDataByStockId, stockIds, checkStockExistence } = useStockSocket();

export const useSocketStockData = (
  stockInfo: any,
) => {
  const stockData = ref<SocketStock>();

  onMounted(() => {
    updateStockInfoObject(stockInfo);
  })

  const updateStockInfoObject = (obj: any) => {
    stockInfo = obj;
    if (stockInfo) {
      stockData.value = SocketStock.convertToStockObject(stockData.value, stockInfo);
      if (stockInfo && stockInfo._id) {
        checkStockExistence(stockInfo._id, stockData.value?.fiveDayTrend != undefined);
        setTimeout(()=>{
          updateStockObject();
        },500);
      }
      updateStockObject();
    }
  }

  const updateStockObject = () => {
    var data = getDataByStockId(stockData.value?._id!);
    if (data) {
      SocketStock.copy(stockData.value!, data);
    }
    setTimeout(() => {
      updateStockObject();
    }, 3000);
  }

  return {
    updateStockInfoObject,
    stockData,
  };
};
