import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isPlaceholder)
    ? (_openBlock(), _createElementBlock("figure", {
        key: 0,
        class: _normalizeClass(["stock_logo placeholder", { placeholderWithContent: _ctx.placeholderContent }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goToStockDetail && _ctx.goToStockDetail(...args)), ["stop"])),
        onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToStockDetail && _ctx.goToStockDetail(...args))),
        style: _normalizeStyle({
      ..._ctx.style,
      width: _ctx.size?.width,
      height: _ctx.size?.height,
      flex: `0 0 ${_ctx.size?.width}`,
    })
      }, [
        (_ctx.placeholderContent)
          ? (_openBlock(), _createElementBlock("figcaption", _hoisted_1, _toDisplayString(`${_ctx.placeholderContent}+`), 1))
          : _createCommentVNode("", true)
      ], 38))
    : (_openBlock(), _createElementBlock("figure", {
        key: 1,
        class: _normalizeClass(["stock_logo", _ctx.market]),
        onTouchstart: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goToStockDetail && _ctx.goToStockDetail(...args))),
        onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goToStockDetail && _ctx.goToStockDetail(...args)), ["stop"])),
        style: _normalizeStyle({
      ..._ctx.style,
      width: _ctx.size?.width,
      height: _ctx.size?.height,
      flex: `0 0 ${_ctx.size?.width}`,
    })
      }, [
        (_ctx.stock)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.$filters.validStockLogoUrl(_ctx.stock.primaryLogoUrl))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    onError: _cache[2] || (_cache[2] = 
          (event) =>
            _ctx.onImgLoadError(
              event,
              _ctx.stock?.secondaryLogoUrl,
              _ctx.stock?.tertiaryLogoUrl
            )
        ),
                    ref: "imgTag",
                    src: _ctx.stock.primaryLogoUrl,
                    style: _normalizeStyle({
          width: _ctx.size?.width,
          height: _ctx.size?.height,
          flex: `0 0 ${_ctx.size?.width}`,
        })
                  }, null, 44, _hoisted_2))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    ref: "imgTag",
                    onError: _cache[3] || (_cache[3] = 
          (event) =>
            _ctx.onImgLoadError(
              event,
              _ctx.stock?.secondaryLogoUrl,
              _ctx.stock?.tertiaryLogoUrl
            )
        ),
                    src: _ctx.getStockImage(_ctx.symbol, _ctx.market),
                    style: _normalizeStyle({
          width: _ctx.size?.width,
          height: _ctx.size?.height,
          flex: `0 0 ${_ctx.size?.width}`,
        })
                  }, null, 44, _hoisted_3))
            ], 64))
          : _createCommentVNode("", true)
      ], 38))
}