import { ConnectionType } from "@/composables/connection/models/request.model";
import { NotificationModel } from "@/helpers/models/notification.model";
import User from "@/helpers/models/user.model";
import httpService from "@/services/http.service";
import storageService from "@/services/storage.service";
import utilService from "@/services/util.service";
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  startAt,
  Unsubscribe,
  where,
} from "firebase/firestore";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import moment from "moment";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");
let unSubscribe: Unsubscribe;
const notifications = ref<NotificationModel[]>([]);
const isNotificationsLoading = ref<boolean>(false);
const notificationExceptTypes = ["post"];
const isAnyNotificationUnread = computed<boolean>(() => {
  return (
    notifications.value.filter(
      (x) =>
        !x.isViewed &&
        !notificationExceptTypes?.includes(x.notificationType || "")
    ).length > 0
  );
});
const showMoreBtn = ref<boolean>(false);
const allTabs = ref([
  "All",
  "Requests",
  "Predictions",
  "Watchlists",
  "Favorite Investments",
  "Profit And Loss",
  "Top Stats",
  "Links",
  "Points",
  "Favorite Influencers",
  "Images",
  "Favorite Platforms",
  "Recommendations",
  "Social Accounts",
  "Bio",
  "Trader Types",
  "Highlight Stats Left",
  "Highlight Stats Right",
  "Playbooks",
  "Best Trades",
]);
const currentTab = ref(allTabs.value[0]);
const page = ref<number>(0);
const count = ref<number>(20);
const unReadCount = ref<any>();
export const NotificationComposables = () => {
  const clearNotifications = () => {
    unSubscribe();
  };

  const FilteredArray = computed(() => {
    if (currentTab.value == "Requests") {
      return notifications.value.filter(
        (x) =>
          x.notificationType == "following" ||
          x.notificationType == "friendrequest" ||
          x.notificationType == "friendRequestAccepted" ||
          x.notificationType == "friends"
      );
    } else if (currentTab.value == "Playbooks") {
      return notifications.value.filter(
        (x) => x.notificationType == "playbook"
      );
    } else if (currentTab.value == "Predictions") {
      return notifications.value.filter(
        (x) => x.notificationType == "predictions"
      );
    } else if (currentTab.value == "Watchlists") {
      return notifications.value.filter(
        (x) => x.notificationType == "watchlists"
      );
    } else if (currentTab.value == "Favorite Investments") {
      return notifications.value.filter(
        (x) => x.notificationType == "favouriteInvestment"
      );
    } else if (currentTab.value == "Profit And Loss") {
      return notifications.value.filter(
        (x) => x.notificationType == "profitAndLoss"
      );
    } else if (currentTab.value == "Top Stats") {
      return notifications.value.filter(
        (x) => x.notificationType == "topStats"
      );
    } else if (currentTab.value == "Links") {
      return notifications.value.filter((x) => x.notificationType == "links");
    } else if (currentTab.value == "Points") {
      return notifications.value.filter((x) => x.notificationType == "points");
    } else if (currentTab.value == "Favorite Influencers") {
      return notifications.value.filter(
        (x) => x.notificationType == "favouriteInfluencer"
      );
    } else if (currentTab.value == "Images") {
      return notifications.value.filter((x) => x.notificationType == "images");
    } else if (currentTab.value == "Favorite Platforms") {
      return notifications.value.filter(
        (x) => x.notificationType == "portfolio"
      );
    } else if (currentTab.value == "Recommendations") {
      return notifications.value.filter(
        (x) => x.notificationType == "recommendation"
      );
    } else if (currentTab.value == "Social Accounts") {
      return notifications.value.filter(
        (x) => x.notificationType == "socialLink"
      );
    } else if (currentTab.value == "Bio") {
      return notifications.value.filter(
        (x) => x.notificationType == "update user"
      );
    } else if (currentTab.value == "Trader Types") {
      return notifications.value.filter(
        (x) => x.notificationType == "traderType"
      );
    } else if (currentTab.value == "Highlight Stats Right") {
      return notifications.value.filter(
        (x) => x.notificationType == "highlightStatsRight"
      );
    } else if (currentTab.value == "Highlight Stats Left") {
      return notifications.value.filter(
        (x) => x.notificationType == "highlightStats"
      );
    } else if (currentTab.value == "Playbooks") {
      return notifications.value.filter(
        (x) => x.notificationType == "playbook"
      );
    } else if (currentTab.value == "Best Trades") {
      return notifications.value.filter(
        (x) => x.notificationType == "winningTrades"
      );
    }
    return notifications.value;
  });

  const unviewedItemsCount = computed(() => {
    return FilteredArray.value.filter(
      (item) =>
        !item.isViewed &&
        !notificationExceptTypes?.includes(item.notificationType || "")
    ).length;
  });

  interface Notification {
    notificationType?: string;
    isViewed?: boolean;
  }

  const notificationTypeMapping: Record<string, string> = {
    // following: "Following",
    friendrequest: "Requests",
    following: "Requests",
    friends: "Requests",
    friendRequestAccepted: "Requests",
    // friends: "Friends",
    playbook: "Playbooks",
    predictions: "Predictions",
    watchlists: "Watchlists",
    favouriteInvestment: "Favorite Investments",
    profitAndLoss: "Profit And Loss",
    topStats: "Top Stats",
    links: "Links",
    points: "Points",
    favouriteInfluencer: "Favorite Influencers",
    images: "Images",
    portfolio: "Favorite Platforms",
    recommendation: "Recommendations",
    socialLink: "Social Accounts",
    "update user": "Bio",
    traderType: "Trader Types",
    highlightStatsRight: "Highlight Stats Right",
    highlightStats: "Highlight Stats Left",
    winningTrades: "Best Trades",
  };

  const notificationCounts = computed(() => {
    return notifications.value.reduce(
      (acc: Record<string, number>, notification: Notification) => {
        const type = notification.notificationType;
        const readableType = type ? notificationTypeMapping[type] : undefined;

        if (notification?.isViewed) {
          return acc;
        }
        if (readableType) {
          // Increment total count
          acc["All"] = (acc["All"] || 0) + 1;
          acc[readableType] = (acc[readableType] || 0) + 1;
        }

        return acc;
      },
      {} as Record<string, number>
    );
  });

  const showMore = () => {
    page.value = page.value + 1;
    getNotifications();
  };

  const getDb = () => {
    const db = getFirestore();
    return db;
  };

  const unregisterSubscribe = () => {
    notifications.value = [];
    if (unSubscribe) {
      unSubscribe();
    }
  };

  const changeTab = (tab: string) => {
    currentTab.value = tab;
  };

  var lastDoc: any;

  const listenNotifications = async () => {
    if (unSubscribe) {
      unSubscribe();
    }
    let response = await httpService.getNotificationSettings();
    var userId = storageService.user.value?._id;
    var createdOn = storageService.user.value?.createdOn;
    let snapshotInQuery = [userId];
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.receiveFrom &&
      response.data.data.receiveFrom.public
    ) {
      snapshotInQuery.push("public");
    }
    if (userId) {
      isNotificationsLoading.value = true;
      const db = getDb();
      if (createdOn) {
        var q = query(
          collection(db, "notifications"),
          where("userId", "in", snapshotInQuery),
          where("createdOn", ">", createdOn),
          orderBy("createdOn", "desc")
        );
      } else {
        var q = query(
          collection(db, "notifications"),
          where("userId", "in", snapshotInQuery),
          orderBy("createdOn", "desc")
        );
      }
      if (lastDoc) {
        let d = lastDoc.data();
        q = query(q, where("createdOn", ">", d.createdOn));
      }
      unSubscribe = onSnapshot(q, (querySnapshot) => {
        isNotificationsLoading.value = false;
        handleNotificationResponse(querySnapshot, false);
      });
    }
  };

  const getNotifications = async () => {
    try {
      notifications.value = [];
      if (unSubscribe) {
        unSubscribe();
      }
      let response = await httpService.getNotificationSettings();
      var userId = storageService.user.value?._id;
      var createdOn = storageService.user.value?.createdOn;
      let snapshotInQuery = [userId];
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.receiveFrom &&
        response.data.data.receiveFrom.public
      ) {
        snapshotInQuery.push("public");
      }

      if (userId) {
        var countBefore = page.value * count.value;
        const db = getDb();
        if (createdOn) {
          var q = query(
            collection(db, "notifications"),
            where("userId", "in", snapshotInQuery),
            where("createdOn", ">", createdOn),
            orderBy("createdOn", "desc"),
            limit(100)
          );
        } else {
          var q = query(
            collection(db, "notifications"),
            where("userId", "in", snapshotInQuery),
            orderBy("createdOn", "desc"),
            limit(100)
          );
        }
        const querySnapshot = await getDocs(q);
        await handleNotificationResponse(querySnapshot);
        if (countBefore == 0) {
          lastDoc = querySnapshot.docs[0];
          listenNotifications();
        }
      }
    } catch (e) {}
  };

  const handleNotificationResponse = (
    querySnapshot: QuerySnapshot,
    handleMore: boolean = true
  ) => {
    for (var i = 0; i < querySnapshot.docs.length; i++) {
      if (i == count.value) {
        if (handleMore) {
        }
      } else {
        if (handleMore) {
          showMoreBtn.value = false;
        }
        let doc = querySnapshot.docs[i];
        let tempNotification = new NotificationModel();

        tempNotification = { ...doc.data() } as NotificationModel;
        if (
          tempNotification?.notificationType == "update user" &&
          tempNotification?.data?.message.includes("has updated the bio")
        ) {
          tempNotification.data.message = "has updated the bio";
        }
        let createdOn = doc.data().createdOn;
        tempNotification.createdOn = new Date(+createdOn).toISOString();
        tempNotification = updatedNotification(
          tempNotification,
          storageService.user.value
        );
        tempNotification._id = doc.id;
        if (tempNotification.notificationType === "post") {
          tempNotification.data.userProfilePicture =
            tempNotification.data.profilePicture;
        }
        if (tempNotification.notificationType === "tagged") {
          tempNotification.data.userProfilePicture =
            tempNotification.data.profilePicture;
        }
        tempNotification.data.profilePicture =
          utilService.getUserProfilePicture(
            tempNotification.data.userId!,
            tempNotification.data.userProfilePicture!,
            "male",
            false,
            false
          );
        tempNotification.data.momentTime = timeAgo.format(
          new Date(tempNotification.createdOn!)
        ) as string;
        if (tempNotification.notificationId) {
          var existIndex = notifications.value.findIndex(
            (x) => x.notificationId == tempNotification.notificationId
          );
          if (
            tempNotification.notificationType == ConnectionType.friendRequest &&
            tempNotification.data.requestStatus == "reject"
          ) {
            if (existIndex != -1) {
              notifications.value.splice(existIndex, 1);
            }
            return;
          }
          if (existIndex == -1) {
            var userId = storageService.user.value?._id;
            if (tempNotification.notificationType !== "friendRequestAccepted") {
              if (tempNotification.data.userId !== userId) {
                notifications.value.push(tempNotification);
              }
            } else {
              notifications.value.push(tempNotification);
            }
          } else {
            notifications.value[existIndex] = tempNotification;
          }
        } else {
          var userId = storageService.user.value?._id;
          if (tempNotification.notificationType !== "friendRequestAccepted") {
            if (tempNotification.data.userId !== userId) {
              notifications.value.push(tempNotification);
            }
          } else {
            notifications.value.push(tempNotification);
          }
        }
        notifications.value = notifications.value.sort(
          (a, b) =>
            new Date(b.createdOn!).getTime() - new Date(a.createdOn!).getTime()
        );
      }
    }
  };

  const markNotificationAsRead = (id: string) => {
    httpService
      .readNotification([id])
      .then((res) => {})
      .catch((e) => {});
  };

  const getType = (value: string): string | undefined => {
    let type = Object.entries(notificationTypeMapping).find(
      ([key, val]) => val === value
    )?.[0];
    type = getReadType(type);

    return type;
  };

  const getReadType = (type: any) => {
    if (type == "highlightStats") type = "highlightStatsLeft";
    if (type == "update user") type = "bio";
    if (type == "points") type = "lists";
    if (type == "winningTrades") type = "bestTrades";
    return type;
  };

  const friendRequestTypes = [
    "friendrequest",
    "following",
    "friends",
    "friendRequestAccepted",
  ];
  const doneRequestTypes = ref<any[]>([]);

  const markAllByType = async (notificationType: string | undefined) => {
    let type: string | undefined =
      notificationType || getType(currentTab.value) || "all"; // Use the notificationType if provided, otherwise get the current type.
    // If all types are already processed, stop further execution

    // If type is 'friendrequest', we handle all types in friendRequestTypes
    if (type === "friendrequest") {
      if (doneRequestTypes.value.length === friendRequestTypes.length) return;
      const remainingTypes = friendRequestTypes.filter(
        (item) => !doneRequestTypes.value.includes(item)
      );

      // Mark each remaining type one by one
      for (const item of remainingTypes) {
        if (!doneRequestTypes.value.includes(item)) {
          await markNotification(item); // Mark notification for each type
          doneRequestTypes.value.push(item); // Add the processed type to doneRequestTypes
        }
      }
      return;
    }

    // Mark notifications by type
    const userData = await markNotification(type);

    notifications.value = notifications.value?.map((tempNotification: any) => {
      return updatedNotification(tempNotification, userData);
    });

    // page.value = 0
    // getNotifications()
  };

  const updatedNotification = (
    notification: NotificationModel,
    userData: any
  ) => {
    const createdOn = notification.createdOn;
    let notificationType: string | undefined = notification?.notificationType;
    notificationType = getReadType(notificationType);

    var notificationReadTime:any = undefined;
    if (notificationType) {
      var notificationReadTime:any =
        userData?.notificationReadTime?.[notificationType];
    }
    var readTime = +new Date("1/1/1970").toISOString();
    if (notificationReadTime) {
      readTime = +new Date(notificationReadTime);
    }

    notification.isViewed = moment(readTime).isAfter(createdOn);
    return notification;
  };

  // This function will handle marking notifications by type
  const markNotification = async (type: string) => {
    let user = storageService.user.value;

    try {
      const res = await httpService.readNotificationByType(type);
      if (res.data.isSuccess && user) {
        // Update the user's notification read time based on the response
        user.notificationReadTime = {
          ...user.notificationReadTime,
          ...res.data?.data?.notificationReadTime,
        };
        storageService.setUser(user); // Persist updated user data
        return user;
      }
    } catch (e) {
      console.error("Error marking notification by type:", e);
    }
  };

  const markAllAsRead = () => {
    var ids: string[] = [];
    for (let item of notifications.value) {
      if (!item.isViewed) {
        item.isViewed = true;
        ids.push(item._id!);
      }
    }
    if (ids.length) {
      let user = storageService.user.value;
      // readNotificationByType
      httpService
        .readNotification(ids)
        .then((res) => {
          if (res.data.isSuccess && user) {
            user.notificationReadTime = res.data?.data?.notificationReadTime;
            storageService.setUser(user);
          }
        })
        .catch((e) => {});
    }
    unReadCount.value = ids.length;
  };

  const route = useRoute();
  const isPopoverVisible = ref(false);
  const notificationWrapperClicked = () => {
    isPopoverVisible.value = false;
  };
  watch(() => route?.path, notificationWrapperClicked);

  return {
    FilteredArray,
    changeTab,
    getNotifications,
    showMoreBtn,
    notifications,
    isAnyNotificationUnread,
    isNotificationsLoading,
    allTabs,
    currentTab,
    markNotificationAsRead,
    markAllAsRead,
    markAllByType,
    showMore,
    unregisterSubscribe,
    clearNotifications,
    notificationCounts,
    listenNotifications,
    isPopoverVisible,
    notificationWrapperClicked,
    unReadCount,
    unviewedItemsCount,
  };
};
